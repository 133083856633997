import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { Delete, Add } from '@material-ui/icons';
import {
  AddOutputContainer,
  OutputsContainer,
  ChoicesContainer,
  ChoicesIndexContainer,
  ChoicesTitleContainer,
  ChoicesTitle,
} from 'components/MetadataBlocksDrawerEditor/styles';
import { DynamicBlockOutputs } from 'contexts/Flow/types';
import { useForm } from 'contexts/Form/formContext';
import { SimilarityComponent } from '../SimilarityComponent';
import useTranslator from 'utils/hooks/Translator';
import { ConditionalOutputs } from './ConditionalOutputs';
import { Output } from 'models/DataFlow';
import { getTranslationKeys } from 'utils/i18n/types';
const maxValue = 100;

export function MetaBlocksOutputs({
  outputs,
}: {
  outputs: DynamicBlockOutputs;
}) {
  const { items, minItems, maxItems, hidden } = outputs;
  const { getTranslation } = useTranslator();
  const { state, updateForm } = useForm();
  const { dataForm, formErrors } = state;

  const outputsToManage =
    dataForm?.metadata?.type === 'SimpleCarousel'
      ? dataForm.carouselItems
      : dataForm?.outputs;

  const [sumResults, setSumResults] = useState(
    outputsToManage?.findIndex((o) => !!o.value) !== -1 ? 100 : 0
  );

  const outputsLength = outputsToManage?.length ?? 0;

  const choicesLength =
    outputsLength < Number(minItems)
      ? minItems ?? 0
      : outputsLength ?? minItems ?? 1;

  const hiddenOutput = useMemo(() => {
    const blockType = dataForm?.metadata?.type;
    if (!blockType) return true;

    return (blockType !== 'Multiple' &&
      blockType !== 'Carousel' &&
      blockType !== 'ABTest' &&
      blockType !== 'Conditional' &&
      blockType !== 'Api') ||
      !!maxItems
      ? choicesLength >= Number(maxItems)
      : false;
  }, [choicesLength, dataForm?.metadata?.type, maxItems]);

  const metaBlockType = dataForm?.metadata?.type;

  useEffect(() => {
    const currSum =
      outputsToManage?.reduce((a, b) => a + Number(b.value || 0), 0) || 0;
    setSumResults(currSum);
  }, [outputsToManage]);

  const shouldHaveOthersOutputs = useCallback(() => {
    const metadataType = metaBlockType;
    if (metadataType)
      return (
        (metadataType === 'Carousel' ||
          metadataType === 'Api' ||
          metadataType === 'Multiple' ||
          metadataType === '123AI' ||
          metadataType === 'Conditional') &&
        !outputsToManage?.find((output) => output.title === 'Outros')
      );
  }, [outputsToManage, metaBlockType]);

  if (dataForm && outputsToManage && outputsLength === 0) {
    dataForm.metadata?.type === '123AI' &&
      outputsToManage.push({
        title: 'AI123',
        isChip: false,
      });

    items?.forEach((item) => {
      const translatedTitle = getTranslation(item.title as getTranslationKeys);
      const outputTitle =
        translatedTitle === item.title
          ? item.title
          : getTranslation(translatedTitle as getTranslationKeys);

      outputsToManage!.push({
        title: outputTitle,
        statusCode: item.statusCode ? item.statusCode : undefined,
        ifCondition: item.statusCode
          ? `resposta.statusCode == ${item.statusCode}`
          : '',
        value: '0',
      });

      if (dataForm?.metadata?.type === 'SimpleCarousel')
        dataForm.dataBlockly!.payload = '// #PARSER#CONNECTOR#Saída#';
    });

    if (shouldHaveOthersOutputs()) {
      outputsToManage.push({
        title: 'Outros',
        isChip: false,
      });
    }
  }

  const disabled = choicesLength >= Number(maxItems);

  const handleChangeGPTContext = (
    newContext: string,
    block: Output,
    index: number
  ) => {
    if (dataForm && outputsToManage?.[index]) {
      if (block.title !== 'Outros') {
        block.falaGPTContext = newContext;
        outputsToManage[index] = block;

        updateForm(dataForm);
      }
    }
  };

  const handleChangeValue = (index: number, newValue: string) => {
    if (dataForm && outputsToManage && outputsToManage[index]) {
      outputsToManage[index].value = newValue;

      if (dataForm) updateForm(dataForm);
    }
  };

  const handleChangeOutputTitle = (newTitle: string, index: number) => {
    if (dataForm && outputsToManage && outputsToManage[index]) {
      const choice = outputsToManage[index];
      const choiceSentences = choice.userSentences || [];
      if (choice.title !== 'Outros') {
        choiceSentences[0] = newTitle;
        choice.title = newTitle;
        choice.userSentences = choiceSentences;
        outputsToManage[index] = choice;

        if (dataForm) updateForm(dataForm);
      }
    }
  };

  function handleChangeOutput(
    index: number,
    field: 'statusCode' | 'description' | 'value' | 'url',
    newValue: string
  ) {
    if (!dataForm || !outputsToManage) return;

    const outputToChange = outputsToManage[index];

    if (!outputToChange) return;

    outputToChange[field] = newValue;
    outputsToManage[index] = outputToChange;
    updateForm(dataForm);
  }

  function handleAddOutput() {
    if (!dataForm || !outputsToManage) return;

    if (dataForm.metadata?.type === 'SimpleCarousel') {
      dataForm.carouselItems?.push({
        title: '',
        url: '',
        value: '',
        description: '',
      });
      updateForm(dataForm);

      return;
    }

    const other = outputsToManage.find(
      (o) => o.title.toLowerCase() === 'outros'
    );
    const newOutputs = outputsToManage.filter(
      (o) => o.title.toLowerCase() !== 'outros'
    );
    newOutputs.push({
      title: '',
      userMsg: '',
      userSentences: [''],
      isChip: false,
    });

    if (other) {
      newOutputs.push(other);
    }

    dataForm.outputs = newOutputs;

    updateForm(dataForm);
  }

  function handleRemoveOutput(index: number) {
    if (!dataForm || !outputsToManage) return;
    outputsToManage.splice(index, 1);
    updateForm(dataForm);
  }

  const updateSum = (
    index: number,
    newValue: string,
    operation?: 'sum' | 'subtract'
  ) => {
    if (Number(newValue) < 0) return;
    if (!!dataForm && !!outputsToManage) {
      const value = operation === 'sum' ? Number(newValue) : -Number(newValue);
      let currSum = 0;

      if (operation === 'sum') {
        const outputsExceptIndex = outputsToManage.filter((o) => {
          if (outputsToManage) {
            return o !== outputsToManage[index] ? o : null;
          }
          return null;
        });
        currSum = outputsExceptIndex.reduce(
          (a, b) => a + Number(b.value || 0),
          0
        );
      } else {
        currSum = outputsToManage.reduce((a, b) => a + Number(b.value || 0), 0);
      }

      if (currSum + value <= maxValue) {
        setSumResults(currSum + value);
        handleChangeValue(index, newValue);
      }
    }
  };

  const handleChangeChip = (checked: boolean, index: number) => {
    if (dataForm && outputsToManage && outputsToManage[index]) {
      outputsToManage[index].isChip = checked;
      updateForm(dataForm);
    }
  };

  return hidden ? null : (
    <React.Fragment>
      <AddOutputContainer>
        <IconButton onClick={handleAddOutput} disabled={disabled}>
          <Add />
        </IconButton>
      </AddOutputContainer>
      {outputsToManage?.map((item, index) => {
        return item.title === 'Outros' ? (
          <></>
        ) : (
          <OutputsContainer key={index}>
            <ChoicesContainer>
              <ChoicesIndexContainer>
                <Typography variant="subtitle2">
                  {metaBlockType === 'Conditional'
                    ? 'Condição'
                    : getTranslation('option')}{' '}
                  #{index + 1}
                </Typography>
                <IconButton
                  onClick={() => handleRemoveOutput(index)}
                  disabled={outputsToManage?.length === minItems}
                >
                  <Delete />
                </IconButton>
              </ChoicesIndexContainer>

              <ChoicesTitleContainer>
                <ChoicesTitle>
                  <Typography>{getTranslation('outputTitle')}</Typography>
                  {(metaBlockType === 'Multiple' ||
                    metaBlockType === '123AI') && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.isChip || false}
                          onChange={(e) =>
                            handleChangeChip(e.target.checked, index)
                          }
                          color="primary"
                        />
                      }
                      label={getTranslation('showAsButton')}
                    />
                  )}
                </ChoicesTitle>
                <TextField
                  value={item.title}
                  onChange={(e) =>
                    handleChangeOutputTitle(e.target.value, index)
                  }
                  helperText={formErrors[`outputs[${index}].title`]}
                  error={!!formErrors[`outputs[${index}].title`]}
                  disabled={hiddenOutput}
                />
                {(metaBlockType === 'Conditional' ||
                  metaBlockType === 'Api') && (
                  <ConditionalOutputs
                    item={item}
                    type={metaBlockType}
                    index={index}
                  />
                )}
                {metaBlockType === 'ABTest' && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      style={{ maxWidth: '98px' }}
                      value={item.value || 0}
                      type="number"
                      label={getTranslation('outputValue')}
                      onChange={(e) => updateSum(index, e.target.value, 'sum')}
                      helperText={formErrors[`outputs[${index}].value`]}
                      error={!!formErrors[`outputs[${index}].value`]}
                    />
                    <Typography
                      variant="h6"
                      children="%"
                      style={{ alignSelf: 'center' }}
                    />
                  </div>
                )}
                {metaBlockType === 'Carousel' ||
                  (metaBlockType === 'SimpleCarousel' && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                        width: '100%',
                      }}
                    >
                      <TextField
                        value={item.url}
                        label="URL"
                        fullWidth
                        onChange={(e) =>
                          handleChangeOutput(index, 'url', e.target.value)
                        }
                      />
                      <TextField
                        value={item.value}
                        label={getTranslation('value')}
                        fullWidth
                        onChange={(e) =>
                          handleChangeOutput(index, 'value', e.target.value)
                        }
                      />
                      <TextField
                        value={item.description}
                        label={getTranslation('description')}
                        fullWidth
                        onChange={(e) =>
                          handleChangeOutput(
                            index,
                            'description',
                            e.target.value
                          )
                        }
                      />
                    </div>
                  ))}
              </ChoicesTitleContainer>

              {metaBlockType === '123AI' && index === 0 && (
                <TextField
                  value={item.falaGPTContext}
                  onChange={(e) =>
                    handleChangeGPTContext(e.target.value, item, index)
                  }
                  label="Informe o contexto"
                  fullWidth
                  style={{ marginBottom: '14px' }}
                />
              )}

              {(metaBlockType === 'Multiple' ||
                metaBlockType === 'Carousel' ||
                (metaBlockType === '123AI' && index !== 0)) && (
                <SimilarityComponent item={item} index={index} />
              )}
            </ChoicesContainer>
          </OutputsContainer>
        );
      })}

      {metaBlockType === 'ABTest' && (
        <>
          <div style={{ display: 'flex', marginTop: 16 }}>
            <Typography
              variant="h6"
              children={getTranslation('outputsSum')}
              color="textPrimary"
            />
            <Typography
              variant="h6"
              children={`${sumResults}%`}
              style={{
                color: `${sumResults < 100 ? 'red' : 'green'}`,
                marginLeft: '4px',
              }}
            />
          </div>
          {!!formErrors[`sum`] && (
            <Typography
              children={formErrors['sum']}
              variant="body2"
              color="error"
            />
          )}
        </>
      )}
    </React.Fragment>
  );
}
