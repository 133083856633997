import FintalkCloudHeader from './FintalkCloudHeader';
import {
  AutoCompleteWrap,
  AutoCompletesContainer,
  DashboardContainer,
  DashboardHeader,
  DashboardHeaderContainer,
  ElementContainer,
} from './styles';

import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DefaultLogo from 'components/Icons/logo-longo.png';
import Toast from 'components/Toast';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { CloudData } from 'contexts/FintalkCloud/types';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import {
  IPermissionCompany,
  IPermissionCompanyProject,
} from 'contexts/Permissions/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CognitoLogOut } from 'services/AuthService';
import useTranslator from 'utils/hooks/Translator';
import {
  AutoCompleteOption,
  LabelTitle,
} from 'views/Canvas/Builder/MainComponent/styles';
import FintalkCloudSidebar from './FintalkCloudSidebar';

export function FintalkCloud() {
  const {
    currentElementName,
    getCurrentElement,
    currentData,
    handleChangeCurrentElementName,
    setCurrentData,
  } = useFintalkCloud();
  const { getTranslation } = useTranslator();
  const { dispatch: dispatchApp } = useApp();
  const { getCompanies } = usePermissions();

  const [currentCompany, setCurrentCompany] =
    useState<IPermissionCompany | null>(null);

  const noResultsTranslated = getTranslation('noResults');

  const companies = getCompanies();

  const companiesNames = useMemo(() => {
    if (!companies) return [];
    return companies.map((company) => company.name).sort();
  }, [companies]);

  const projectsNames = useMemo(() => {
    if (!companies) return [];

    const company = companies.find(
      (company) => company.name === currentCompany?.name
    );
    if (company) return company.projects.map((project) => project.name).sort();
    else return [];
  }, [currentCompany, companies]);

  const agentsNames = useMemo(() => {
    if (!projectsNames) return [];

    const project = currentCompany?.projects.find(
      (project) => project.name === currentData.projectName
    );
    if (project) return project.agents.map((agent) => agent).sort();
    else return [];
  }, [currentCompany, currentData.projectName, projectsNames]);

  const handleChangeCompany = useCallback(
    async (companyName: string) => {
      const selectedCompany = companies.find(
        (company) => company.name === companyName
      );

      if (!selectedCompany) return;

      setCurrentCompany(selectedCompany);
      localStorage.setItem('@fintalkCloud:company', selectedCompany.name);
      const newData = verifyLocalProject(selectedCompany);

      setCurrentData((prev) => ({ ...prev, ...newData }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companies, setCurrentData]
  );

  useEffect(() => {
    const localStorageCompany =
      localStorage.getItem('@fintalkCloud:company') ?? '';

    if (localStorageCompany) handleChangeCompany(localStorageCompany);
  }, [handleChangeCompany]);

  function verifyLocalProject(company: IPermissionCompany) {
    const localStorageProject =
      localStorage.getItem('@fintalkCloud:project') ?? '';

    const newData: CloudData = {
      ...currentData,
      companyName: company.name,
      projectName: localStorageProject,
    };

    let project: IPermissionCompanyProject | undefined = undefined;
    let projectName = '';

    if (localStorageProject) {
      const foundProject = company.projects.find(
        (project) => project.name === localStorageProject
      );
      project = foundProject;

      if (foundProject) {
        projectName = foundProject.name;
        localStorage.setItem('@fintalkCloud:project', foundProject.name);
        return newData;
      }
    }

    if (!projectName && company.projects[0]) {
      project = company.projects[0];
      const firstProject = company.projects.sort((projectA, projectB) =>
        projectA.name.localeCompare(projectB.name)
      )[0].name;

      if (!!firstProject) {
        projectName = firstProject;
        verifyLocalAgent(company.projects[0]);
      }
    }

    localStorage.setItem('@fintalkCloud:project', projectName);
    const agent = verifyLocalAgent(project!);

    newData.projectName = projectName ?? '';
    newData.agentName = agent ?? '';
    newData.groupName =
      localStorage.getItem('@fintalkCloud:group') ?? 'principal';

    handleChangeProject(projectName);
    handleChangeCurrentElementName('builder');
    return newData;
  }

  function verifyLocalAgent(project: IPermissionCompanyProject) {
    const localStorageAgent =
      localStorage.getItem('@fintalkCloud:project') ?? '';

    if (localStorageAgent) {
      const foundAgent = project.agents.find(
        (agent) => agent === localStorageAgent
      );

      if (foundAgent) {
        localStorage.setItem('@fintalkCloud:agent', foundAgent);
        return foundAgent;
      }
    }

    if (project.agents[0]) {
      const agentName = project.agents.sort((agentA, agentB) =>
        agentA.localeCompare(agentB)
      )[0];

      if (!!agentName) {
        localStorage.setItem('@fintalkCloud:agent', agentName);
        return agentName;
      }
    }
  }

  function handleChangeProject(project: string) {
    setCurrentData((prev) => ({
      ...prev,
      projectName: project,
    }));
  }

  function handleChangeAgent(agent: string) {
    localStorage.setItem('@fintalkCloud:agent', agent);

    setCurrentData((prev) => ({
      ...prev,
      agentName: agent || '',
    }));
  }

  async function handleLogOut() {
    await CognitoLogOut();
    dispatchApp({ type: 'updateApp', data: { signed: false } });
  }

  return (
    <DashboardContainer>
      <DashboardHeader>
        <DashboardHeaderContainer>
          <AutoCompletesContainer>
            <img src={DefaultLogo} alt="logo" />

            <AutoCompleteWrap>
              <LabelTitle>{getTranslation('company')}</LabelTitle>

              <Autocomplete
                value={currentCompany?.name || ''}
                options={companiesNames}
                noOptionsText={noResultsTranslated}
                renderOption={(option) => (
                  <AutoCompleteOption>{option}</AutoCompleteOption>
                )}
                onChange={(event: any) => {
                  handleChangeCompany(String(event.target.textContent));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={getTranslation('selectCompany')}
                  />
                )}
              />
            </AutoCompleteWrap>
            <AutoCompleteWrap>
              <LabelTitle>{getTranslation('project')}</LabelTitle>

              <Autocomplete
                value={currentData.projectName}
                disabled={!currentCompany}
                options={projectsNames}
                noOptionsText={noResultsTranslated}
                renderOption={(option) => (
                  <AutoCompleteOption>{option}</AutoCompleteOption>
                )}
                onChange={(event: any, newValue: string | null) =>
                  handleChangeProject(newValue ?? '')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={getTranslation('selectCompany')}
                  />
                )}
              />
            </AutoCompleteWrap>
            {(currentElementName === 'campaign' ||
              currentElementName === 'crm') && (
              <AutoCompleteWrap>
                <LabelTitle>{getTranslation('agent')}</LabelTitle>

                <Autocomplete
                  value={currentData.agentName}
                  disabled={!currentData.projectName}
                  options={agentsNames}
                  noOptionsText={noResultsTranslated}
                  renderOption={(option) => (
                    <AutoCompleteOption>{option}</AutoCompleteOption>
                  )}
                  onChange={(event: any, newValue: string | null) =>
                    handleChangeAgent(newValue ?? '')
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={getTranslation('selectCompany')}
                    />
                  )}
                />
              </AutoCompleteWrap>
            )}
          </AutoCompletesContainer>

          <div style={{ padding: 8 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLogOut}
            >
              logout
            </Button>
          </div>
        </DashboardHeaderContainer>

        <div style={{ display: 'flex' }}>
          <FintalkCloudHeader />
        </div>
      </DashboardHeader>

      <Toast />

      <ElementContainer>
        <FintalkCloudSidebar />
        {getCurrentElement[currentElementName]}
      </ElementContainer>
    </DashboardContainer>
  );
}
