import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';

import {
  addNodeColor,
  blockRightClickEvent,
  fixInputs,
  fixInputsAndOutputs,
  fixOutputs,
  getNodePosition,
  getSelectedNodeId,
  isSelectedNode,
  markCreated,
  markSelectNodes,
  mountNode,
  mountOutputsName,
  putNodeDown,
  putNodeUp,
  removeDeleteButton,
} from 'components/Flow/utils/NodeContainer';
import CreateIntentController from 'controllers/intent/CreateIntentController';
import RemoveIntentController from 'controllers/intent/RemoveIntentController';
import UpdateIntentController from 'controllers/intent/UpdateIntentController';
import EIntentType, { validateBlockedIntents } from 'enums/EIntentType';
import { DataFlow, DataForm, ItemDataFlow, Output } from 'models/DataFlow';
import DataFormModel from 'models/DataFormModel';
import { DrawflowModel } from 'models/DrawflowModel';
import { GetBotsNames } from 'services/BotService';
import { GetCompanies } from 'services/CompaniesService/Company';
import {
  BlockGroupAccess,
  GetGroup,
  GetGroupBlocksCounts,
  ReleaseGroupAccess,
  UpdateGroup,
  UpdateLastAccess,
} from 'services/FlowService';
import { initialState, reducerFlow } from './flowReducers';
import {
  IFlowContext,
  IFlowContextActionsTypes,
  IFlowProviderProps,
  NodeBlock,
  ToastType,
} from './types';

import 'components/Flow/styles';

import { exit_logic } from 'components/BlocklyConstructor/logic';
import BlockAccessCount from 'components/ModalBlockOptions/BlockAccessCount';
import AddBlockToFlowController from 'controllers/library/AddBlockToFlowController';
import GetBlockOnFlowController from 'controllers/library/GetBlockOnFlowController';
import GetOffsetPositionFlowController from 'controllers/library/GetOffsetPositionFlowController';
import UpdateNodesPositionController from 'controllers/node/UpdateNodesPositionController';
import { isEmptyFlow, isValidPrincipalGroup } from 'utils/Helpers';
import {
  restoreBackupBotGroup,
  saveBackupBotGroup,
} from 'utils/ImportBot/BackupBot';
import { useApp } from '../App/appContext';

import MetadataBlock from 'components/ModalBlockOptions/MetadataBlock';
import { Company } from 'contexts/Company/types';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { IPermissionCompany } from 'contexts/Permissions/types';
import {
  DeleteFlexBlock,
  UpdateFlexBlockGroups,
} from 'services/FlexBlocksService';
import { RemoveFlexBlock } from 'services/FlexBlocksService/types';
import useTranslator from 'utils/hooks/Translator';

const inactiveUserTimer = 3600000; //60 minutos de inatividade
const resetTimerToAccess = 3540000; //59 minutos para atualizar no backend

const FlowContext = createContext<IFlowContext>({} as IFlowContext);

export const FlowProvider: React.FC<IFlowProviderProps> = (
  props: IFlowProviderProps
) => {
  const { children } = props;
  const { setCurrentData } = useFintalkCloud();
  const [state, dispatch] = useReducer(reducerFlow, initialState);
  const { dispatch: dispatchPermissions } = usePermissions();

  const { dispatch: dispatchApp } = useApp();
  const isMacOS = window.navigator.appVersion.indexOf('Mac') !== -1;
  const timeInactiveUser = useRef<NodeJS.Timeout>(setInterval(() => {}, 1000));
  const timeToRefreshAccess = useRef<NodeJS.Timeout>(
    setInterval(() => {}, 1000)
  );
  const { getTranslation } = useTranslator();

  useEffect(() => {
    window.addEventListener('beforeunload', async (e) => {
      if (state.idGroup && state.botName && state.isEditing) {
        await ReleaseGroupAccess(
          {
            bot_name: state.botName,
            groupName: state.idGroup,
          },
          dispatchApp
        );
      }
      e.preventDefault();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.botName, state.idGroup, state.isEditing]);

  const startEditing = () => {
    if (state.editorFlow) {
      state.isEditing = true;
      state.editorFlow.editor_mode = 'edit';
      resetTimer();
      window.onmousemove = () => resetTimer();
      window.onmousedown = () => resetTimer();
      dispatch({ type: 'isEditing' });
      dispatch({ type: 'closeModalBlockedGroup' });
      refreshAccessTime();
      setCurrentData((prev) => ({ ...prev, isEditing: true }));
    }
  };

  const stopEditing = (modalType?: IFlowContextActionsTypes) => {
    if (state.editorFlow) {
      state.isEditing = false;
      state.editorFlow.editor_mode = 'fixed';
      window.onmousemove = null;
      window.onmousedown = null;
      clearInterval(timeInactiveUser.current);
      clearInterval(timeToRefreshAccess.current);
      dispatch({ type: 'isNotEditing' });
      setCurrentData((prev) => ({ ...prev, isEditing: false }));

      if (modalType) dispatch({ type: modalType });
    }
  };

  const refreshAccessTime = () => {
    if (state.isEditing) {
      clearInterval(timeToRefreshAccess.current);

      timeToRefreshAccess.current = setInterval(async () => {
        const sBotAndGroup = localStorage.getItem('current_bot_group');

        if (sBotAndGroup) {
          const { botName, groupName } = JSON.parse(sBotAndGroup);
          await UpdateLastAccess(
            {
              bot_name: botName,
              groupName,
            },
            dispatchApp
          );
        }
        refreshAccessTime();
      }, resetTimerToAccess);
    }
  };

  const resetTimer = () => {
    clearInterval(timeInactiveUser.current);
    timeInactiveUser.current = setInterval(async () => {
      const sBotAndGroup = localStorage.getItem('current_bot_group');

      if (sBotAndGroup) {
        const { botName, groupName } = JSON.parse(sBotAndGroup);
        let dataBlockly;
        try {
          dataBlockly = exit_logic();
        } catch (e) {}

        dispatch({
          type: 'updateForm',
          data: { botName, idGroup: groupName },
        });

        stopEditing('openModalWarning');

        await ReleaseGroupAccess(
          {
            bot_name: botName,
            groupName,
          },
          dispatchApp
        );

        if (state.editorFlow) {
          saveBackupBotGroup({
            dataflow: state.editorFlow.drawflow ?? {},
            group: groupName,
            botName: botName,
          });

          const nodeId = Number(localStorage.getItem('currentNode'));
          const node = state.editorFlow.drawflow.drawflow.Home.data[nodeId];

          if (node) {
            const dataForm: DataForm = node.data;
            if (dataForm.dataBlockly) {
              dataForm.dataBlockly = {
                ...(dataBlockly || {
                  payload: dataForm.dataBlockly.payload,
                  xml: dataForm.dataBlockly.xml,
                }),
                lastVersion: dataForm.dataBlockly.lastVersion + 1 || 0,
                compiledPayload: dataForm.dataBlockly.compiledPayload,
              };
            }
          }
          await UpdateGroup(
            {
              bot_name: botName,
              group_name: groupName,
              blocks: {
                drawflow: state.editorFlow.drawflow.drawflow,
              },
            },
            dispatchApp
          );
        }

        dispatch({
          type: 'updateForm',
          data: { openModalForms: false },
        });
      }

      clearInterval(timeInactiveUser.current);
    }, inactiveUserTimer);
  };

  const verifyEditGroup = async (groupName: string, botName: string) => {
    const isGroupLiberate = await BlockGroupAccess(
      {
        bot_name: botName,
        groupName,
      },
      dispatchApp
    );

    let user = '';

    if (!isGroupLiberate.Data.group_is_liberated) {
      window.onmousemove = null;
      window.onmousedown = null;

      dispatch({ type: 'isNotEditing' });
      dispatch({ type: 'closeModalBlockedGroup' });

      if (state.editorFlow) {
        state.isEditing = false;
        state.editorFlow.editor_mode = 'fixed';
      }
      user = isGroupLiberate.Data.current_user;
      localStorage.setItem('user_group', user);

      dispatch({
        type: 'openModalUsedGroup',
      });
      setCurrentData((prev) => ({ ...prev, isEditing: false }));
    } else {
      user = isGroupLiberate.Data.current_user;
      await loadFlow(groupName, botName, true);
    }
  };

  const loadFlow = async (
    groupName: string,
    botName: string,
    verifyEdit = false
  ) => {
    const groupNewVersion = JSON.stringify(state.editorFlow?.drawflow);
    localStorage.setItem('groupLastVersion', groupNewVersion);

    if (state.idGroup?.startsWith('flex-') && state.isEditing)
      await flexGroupTreatment();

    if (!state.editorFlow) return;
    dispatch({ type: 'updateForm', data: { loading: true } });

    if (state.openModalWarning) {
      dispatch({ type: 'closeModalWarning' });
    }

    if (state.openModalUsedGroup) {
      dispatch({ type: 'closeModalUsedGroup' });
    }

    const result = await GetGroup(
      {
        bot_name: botName,
        groupName,
      },
      dispatchApp
    );

    if (result.Success) {
      if (!verifyEdit) {
        stopEditing('openModalBlockedGroup');
      } else {
        startEditing();
        saveBackupBotGroup({
          dataflow: state.editorFlow?.drawflow ?? {},
          group: groupName ?? '',
          botName: botName,
        });
      }

      let count;

      if (state.showBlockAccessCount) {
        const resultCount = await GetGroupBlocksCounts(
          botName,
          groupName,
          dispatchApp
        );

        if (resultCount.Success) {
          count = resultCount.Data.interation_counts;
          if (count !== state.setAccessCountData) {
            dispatch({
              type: 'setAccessCountData',
              data: { setAccessCountData: count },
            });
          }
        }
      }

      localStorage.setItem(
        'current_bot_group',
        JSON.stringify({ botName, groupName })
      );

      if (!result.Data.data.blocks.drawflow) {
        state.editorFlow.clear();
      } else {
        const blocks = result.Data.data.blocks.drawflow;
        const home = blocks.Home;
        const drawflowModel = new DrawflowModel(home.data, groupName);
        home.data = drawflowModel.getDrawflow();

        const keys = Object.keys(home.data);
        for (const key of keys) {
          const block = home.data[Number(key)];
          fixOutputsAndPayload(block);
          fixOutputs(block, home.data);
          fixInputs(block, home.data);
        }

        if (groupName === 'principal') {
          const welcome = home.data[1];
          const fallback = home.data[2];
          const cancel = home.data[3];

          if (welcome && fallback && cancel) {
            if (Object.keys(welcome.inputs).length !== 0) {
              welcome.inputs = {};
            }

            if (Object.keys(cancel.inputs).length === 0) {
              cancel.inputs = { input_1: { connections: [] } };
            }

            if (Object.keys(fallback.outputs).length === 0) {
              fallback.data.outputs = [
                {
                  title: 'Saída',
                  nameIntent: '',
                },
              ];
            }

            if (Object.keys(cancel.outputs).length === 0) {
              cancel.data.outputs = [
                {
                  title: 'Saída',
                  nameIntent: '',
                },
              ];
            }
          } else {
            toastNotification(
              'error',
              'Parece que a integridade do grupo principal foi comprometida e um ou mais blocos principais não foram encontrados. Tente restaurar/importar uma versão anterior.'
            );
          }
        }

        const updatePositionController = new UpdateNodesPositionController(
          blocks
        );
        updatePositionController.resetPosition();
        let drawflow = updatePositionController.getDrawflow();

        try {
          state.editorFlow.import({ drawflow });
        } catch (e) {
          console.log(e);
          drawflow = fixInputsAndOutputs(drawflow);
          state.editorFlow.import({ drawflow });
        }
        const drawFlowRef = { ...drawflow };
        const dataFlow = home.data;

        handleZoomAll();
        dispatch({
          type: 'updateForm',
          data: {
            drawFlowRef,
            dataForm: null,
          },
        });
        if (dataFlow) {
          mountNodeContent(
            dataFlow,
            count || state.setAccessCountData,
            state.showBlockAccessCount
          );

          setCurrentData((prev) => ({
            ...prev,
            agentName: botName,
            groupName,
          }));
        }
        if (!verifyEdit) goToFlowStart();
      }
    } else {
      state.editorFlow.clear();
      toastNotification(
        'error',
        result.Message || 'Ocorreu um erro ao recuperar grupo'
      );
    }

    dispatch({ type: 'updateForm', data: { loading: false } });
  };

  const fixOutputsAndPayload = (block: ItemDataFlow) => {
    if (
      block &&
      block.data &&
      block.data.outputs &&
      block.data.dataBlockly &&
      block.data.outputs.length === 1
    ) {
      if (
        (block.data.outputs[0] && block.data.outputs[0].title === 'Padrão') ||
        block.data.intentType === 2 ||
        block.data.intentType === 6
      ) {
        block.data.outputs[0].title = 'Saída';
        const blockHasSaidaOutput = block.data.dataBlockly.xml.includes(
          '<field name="TEXT">Saída</field>'
        );

        if (blockHasSaidaOutput) return;

        if (block.data.dataBlockly.xml.includes('</xml>')) {
          const newPayload =
            block.data.dataBlockly.payload + '// #PARSER#CONNECTOR#Saída#\n';

          block.data.dataBlockly.payload = newPayload;

          const xmlCloseTagPosition =
            block.data.dataBlockly.xml.indexOf('</xml>');

          block.data.dataBlockly.xml = block.data.dataBlockly.xml.substring(
            0,
            xmlCloseTagPosition
          );

          const xPosition = block.data.dataBlockly.xml.indexOf('x="');
          const lastYPosition = block.data.dataBlockly.xml.lastIndexOf('y="');

          const posX = block.data.dataBlockly.xml.substring(
            xPosition + 3,
            block.data.dataBlockly.xml.indexOf('"', xPosition + 3)
          );

          const posY = block.data.dataBlockly.xml.substring(
            lastYPosition + 3,
            block.data.dataBlockly.xml.indexOf('"', lastYPosition + 3)
          );

          const numberPosY = Number(posY) + 50;

          block.data.dataBlockly.xml += `<block type="connector" id="cAsx5InyFQ]_.i,bM.[~" x="${posX}" y="${numberPosY}"><field name="TEXT">Saída</field></block></xml>`;
        } else {
          block.data.dataBlockly.xml =
            '<xml xmlns="https://developers.google.com/blockly/xml"><block type="connector" id="cAsx5InyFQ]_.i,bM.[~" x="95" y="65"><field name="TEXT">Saída</field></block></xml>';
          block.data.dataBlockly.payload += '// #PARSER#CONNECTOR#Saída#\n';
        }
      }
    }
  };

  const goToFlowStart = () => {
    const block = state.editorFlow?.drawflow.drawflow.Home.data;
    const keys = Object.keys(block);
    const positionsX = keys.map((key) => block[Number(key)].pos_x); //all x values of blocks

    const smallestPosX = Math.min(...positionsX); //most to the left position

    const minBlock = keys.find(
      (key) => block[Number(key)].pos_x === smallestPosX
    );

    const { x, y } = getNodePosition(minBlock as unknown as number);

    const newX = x / 2 + x * 0.1;
    const newY = y / 2 + y * 0.05;

    if (state.editorFlow) {
      state.editorFlow.canvas_x = -newX;
      state.editorFlow.canvas_y = -newY;
      if (state.editorFlow.precanvas) {
        state.editorFlow.precanvas.style.transform = `translate(${state.editorFlow.canvas_x}px, 
          ${state.editorFlow.canvas_y}px) 
          scale(${state.editorFlow.zoom}) `;
      }
    }
  };

  const goToNodePosition = (nodeId: number) => {
    const { x, y } = getNodePosition(nodeId);

    if (state.editorFlow) {
      handleZoomAll();
      const newX = x / 2 + x * 0.01;
      const newY = y / 2 + y * 0.01;
      state.editorFlow.canvas_x = -newX;
      state.editorFlow.canvas_y = -newY;

      if (state.editorFlow.precanvas) {
        const drawFlowElement = document.getElementsByClassName('drawflow');

        drawFlowElement[0]?.classList.add('smooth-transition');
        state.editorFlow.precanvas.style.transition =
          'transform 500ms ease-in-out !important;';
        state.editorFlow.precanvas.style.transform = `
				translate(${state.editorFlow.canvas_x}px, 
          ${state.editorFlow.canvas_y}px) 
          scale(0.6) `;

        setTimeout(() => {
          drawFlowElement[0]?.classList.remove('smooth-transition');
        }, 600);
      }
    }
  };

  const handleZoomAll = () => {
    if (state.editorFlow) {
      if (state.editorFlow.zoom < 0.6) {
        state.editorFlow.zoom_in();
        handleZoomAll();
      } else if (state.editorFlow.zoom > 0.7) {
        state.editorFlow.zoom_out();
        handleZoomAll();
      }
    }
  };

  const addIntent = async (dataForm: DataForm) => {
    const { editorFlow } = state;
    if (!editorFlow) return;
    const intentController = new CreateIntentController(editorFlow);
    intentController.createIntent(dataForm);
    const editorFlowUpdated = intentController.getEditorFlow();
    const nodeIdBeforeImport = editorFlow.nodeId;

    editorFlow.import({ ...editorFlowUpdated.drawflow });
    const dataFlow: DataFlow = editorFlow.drawflow.drawflow.Home.data;

    mountNodeContent(dataFlow);

    if (nodeIdBeforeImport && editorFlow.nodeId) {
      for (let index = nodeIdBeforeImport; index < editorFlow.nodeId; index++) {
        markCreated(index.toString());
      }
    }

    await saveFlow();

    dispatch({ type: 'closeModalIntentType' });
  };

  const updateIntent = async (dataForm: DataForm, id: number) => {
    try {
      const { editorFlow } = state;
      if (!editorFlow) return;

      const intentController = new UpdateIntentController(editorFlow);
      intentController.updateIntent(dataForm, id);
      const editorFlowUpdated = intentController.getEditorFlow();

      editorFlow.import({ ...editorFlowUpdated.drawflow });
      const dataFlow: DataFlow = editorFlow.drawflow.drawflow.Home.data;
      mountNodeContent(dataFlow);

      await saveFlow();
      dispatch({ type: 'closeModalIntentType' });
    } catch (err: any) {
      restoreBackupBotGroup({
        toastNotification,
        state,
        mountNodeContent,
        errors: {
          invalidFileForGroup: getTranslation(
            'toast.error.invalidFileForGroup'
          ),
          invalidFileForPrincipal: getTranslation(
            'toast.error.invalidFileForPrincipal'
          ),
          onlyValidForPrincipal: getTranslation(
            'toast.error.onlyValidForPrincipal'
          ),
        },
      });
    }
  };

  const deleteNode = useCallback(
    (nodeId: number) => {
      if (state.editorFlow) {
        const dataForm: DataForm =
          state.editorFlow.drawflow.drawflow.Home.data[nodeId].data;
        const form = new DataFormModel(dataForm);
        const intentController = new RemoveIntentController(state.editorFlow);
        const isChild = form.isChild();
        if (isChild) {
          intentController.removeNodeChild(nodeId);
        } else {
          intentController.removeNodeParent(nodeId);
        }
        closeNewEditor();
      }
    },
    [state.editorFlow]
  );

  const duplicateNode = (block: NodeBlock) => {
    const { nodeId, node } = block;
    const { editorFlow } = state;
    const dataModel = new DataFormModel(node.data);
    const children = dataModel.isParent()
      ? node.data.outputs?.map(({ outputid }) => Number(outputid))
      : [];

    const blockChildren = children ? [nodeId, ...children] : [nodeId];

    if (editorFlow) {
      const nodeIds = getSelectedNodeId();
      const controller = new GetBlockOnFlowController(editorFlow);

      const idsToGet = nodeIds.length ? nodeIds : blockChildren;
      const blocksJson = JSON.stringify({ ...controller.getBlock(idsToGet) });

      if (editorFlow.drawflow) {
        const offsetPosition = new GetOffsetPositionFlowController(
          editorFlow
        ).getRelativePosition({ ...JSON.parse(blocksJson) });

        const controller = new AddBlockToFlowController(
          editorFlow.drawflow,
          state.idGroup || '',
          offsetPosition
        );
        controller.addBlock({ ...JSON.parse(blocksJson) });
        const drawflow = controller.getDrawflow();

        editorFlow.clear();
        editorFlow.import(drawflow);
        const dataFlow: DataFlow = drawflow.drawflow.Home.data;
        mountNodeContent(dataFlow);
      }
    }
  };

  const selectKeyContent = (e: MouseEvent) => {
    return isMacOS ? e.altKey : e.ctrlKey;
  };

  const mountNodeContent = async (
    data: DataFlow,
    blocksData?: any,
    isCountShowing?: boolean
  ) => {
    const values = { nodeId: 0, x: 0, y: 0 };

    if (!blocksData) {
      blocksData = state.setAccessCountData;
    }

    Object.keys(data).forEach(function (key) {
      const nodeId = Number(key);
      const item = document.getElementById('node-' + nodeId);
      const form = new DataFormModel(data[nodeId].data);
      const currentNode = data[nodeId];
      const container = mountNode(form);
      const renderContainer = document.createElement('div');
      const renderCountContainer = document.createElement('div');

      const countElement = React.createElement(() => {
        return form.intentType === EIntentType.MetadataBlock ? (
          <MetadataBlock
            blocksData={blocksData || {}}
            name={form.name}
            isShowing={true}
            state={state}
            nodeId={nodeId}
            intentType={currentNode.data.intentType}
            node={currentNode}
            dispatch={dispatch}
            addNodeToList={addNodeToList}
            clearNodeList={clearNodeList}
            deleteNode={deleteNode}
            duplicateNode={duplicateNode}
            form={form}
            toastNotification={toastNotification}
          />
        ) : (
          <BlockAccessCount
            blocksData={blocksData || {}}
            name={form.name}
            isShowing={true}
            state={state}
            nodeId={nodeId}
            intentType={currentNode.data.intentType}
            node={currentNode}
            dispatch={dispatch}
            addNodeToList={addNodeToList}
            clearNodeList={clearNodeList}
            deleteNode={deleteNode}
            duplicateNode={duplicateNode}
            form={form}
            toastNotification={toastNotification}
          />
        );
      });

      ReactDOM.render(countElement, renderCountContainer);

      mountOutputsName(nodeId, form.getOutputs(), state.editorFlow);
      addNodeColor(nodeId, form.intentType);
      item?.addEventListener('dblclick', () => {
        if (state.editorFlow?.editor_mode === 'edit') {
          handleEditNode(nodeId);
          updateNode(nodeId, form.intentType === EIntentType.MetadataBlock);
          localStorage.setItem('currentNode', nodeId.toString());
        }
      });

      item?.addEventListener('click', (e) => {
        if (state.isEditing) {
          const dataForm: DataForm =
            state.editorFlow?.drawflow.drawflow.Home.data[nodeId].data;
          const form = new DataFormModel(dataForm);

          const block = { nodeId, node: currentNode } as NodeBlock;
          const { intentType } = currentNode.data;

          if (
            !validateBlockedIntents(intentType) &&
            !form.isChild() &&
            !form.isOthers() &&
            !form.shouldBlockRightClickEvent()
          ) {
            if (e.ctrlKey || selectKeyContent(e)) {
              e.preventDefault();
              e.stopPropagation();
              addNodeToList(block);
            }
            if (e.shiftKey && !form.shouldBlockClone()) {
              duplicateNode(block);
            }
          }
        }
      });

      if (
        form.isParent() ||
        (!form.isChild() &&
          !form.isMain() &&
          form.intentType !== EIntentType.Others &&
          form.intentType !== EIntentType.Welcome)
      ) {
        container.appendChild(renderContainer);
      }

      item?.addEventListener('mousedown', (e) => {
        if (isSelectedNode(nodeId) && !selectKeyContent(e)) {
          values.nodeId = nodeId;
          const { x, y } = getNodePosition(nodeId);
          values.y = y;
          values.x = x;
          putNodeUp(nodeId);
        }
      });

      item?.addEventListener('mouseup', (e) => {
        if (isSelectedNode(values.nodeId) && !selectKeyContent(e)) {
          const result = getNodePosition(nodeId);
          const offsetX = values.x - result.x;
          const offsetY = values.y - result.y;
          const ids = getSelectedNodeId([values.nodeId]);
          const updatePositionController = new UpdateNodesPositionController(
            state.editorFlow?.drawflow.drawflow
          );
          updatePositionController.updatePosition(ids, offsetX, offsetY);
          const drawflow = updatePositionController.getDrawflow();
          state.editorFlow?.clear();
          state.editorFlow?.import({ drawflow });
          mountNodeContent(state.editorFlow?.drawflow.drawflow.Home.data);
          markSelectNodes([...ids, values.nodeId]);
          putNodeDown(nodeId);
        }
      });

      if (form.isParent()) {
        item?.addEventListener('contextmenu', (e) => {
          e.preventDefault();
          e.stopPropagation();
          window.addEventListener('click', () => {
            removeDeleteButton();
          });
          window.addEventListener('contextmenu', () => {
            removeDeleteButton();
          });

          removeDeleteButton();
          const drawflowDelete = document.createElement('div');
          drawflowDelete.setAttribute('class', 'drawflow-delete-parent');
          drawflowDelete.innerHTML = 'x';
          container.parentElement?.appendChild(drawflowDelete);

          drawflowDelete.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            deleteNode(nodeId);
          });
        });
      }

      const content = item?.getElementsByClassName('drawflow_content_node');

      if (form.shouldBlockRightClickEvent()) {
        blockRightClickEvent(item);
      }

      if (form.isChild()) {
        const input = data[nodeId].inputs['input_1'];
        if (input.connections[0]) {
          const idParent = input.connections[0].node;
          const outputParent =
            input.connections[0].input?.replace(/\D/g, '') || '';

          item?.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            e.stopPropagation();
            window.addEventListener('click', () => {
              removeDeleteButton();
            });
            window.addEventListener('contextmenu', () => {
              removeDeleteButton();
            });
            removeDeleteButton();
            const drawflowDelete = document.createElement('div');
            drawflowDelete.setAttribute('class', 'drawflow-delete-parent');
            drawflowDelete.innerHTML = 'x';
            container.parentElement?.appendChild(drawflowDelete);

            drawflowDelete.addEventListener('click', (e) => {
              e.preventDefault();
              e.stopPropagation();
              deleteNode(nodeId);
            });
          });

          disableRemoveConnection(idParent, nodeId, Number(outputParent), 1);
        }
      }
      if (form.isOthers()) {
        const input = data[nodeId].inputs['input_1'];
        if (input.connections[0]) {
          const idParent = input.connections[0].node;
          const outputParent =
            input.connections[0].input?.replace(/\D/g, '') || '';
          disableRemoveConnection(idParent, nodeId, Number(outputParent), 1);
        }
      }

      if (content && content[0]) {
        content[0].appendChild(container);
        content[0].appendChild(renderCountContainer);
      }
    });
  };

  const updateNode = (id: number, newEditor = false) => {
    if (state.editorFlow) {
      const type = newEditor ? 'openModalFormsNewEditor' : 'openModalForms';
      const node = state.editorFlow.getNodeFromId(id);
      dispatch({
        type,
        data: { dataForm: node.data, nodeId: id },
      });
    }
  };

  const closeNewEditor = () => {
    dispatch({
      type: 'closeModalFormsNewEditor',
    });
  };

  const disableRemoveConnection = (
    nodeOutId: number,
    nodeInId: number,
    output: number,
    input: number
  ) => {
    const className = `connection node_in_node-${nodeInId} node_out_node-${nodeOutId} output_${output} input_${input}`;
    const connection = document.getElementsByClassName(className).item(0);

    if (!!connection) {
      const path = connection.getElementsByTagName('path').item(0);
      blockRightClickEvent(path);
    }
  };

  const handleEditNode = (id: number) => {
    const { editorFlow } = state;
    if (editorFlow) {
      const node = editorFlow.getNodeFromId(id);
      const dataForm = node.data as DataForm;
      dispatch({ type: 'updateForm', data: { dataForm, nodeId: id } });
    }
  };

  const generateFlexBlockRelatedToGroup = () => {
    const groupBlocks = state.editorFlow?.drawflow.drawflow.Home.data;
    const groupBlocksKeys = Object.keys(groupBlocks);

    let entryBlock: ItemDataFlow = {} as ItemDataFlow;
    let exitBlocks: ItemDataFlow[] = [];

    for (const key in groupBlocksKeys) {
      const currentBlock = groupBlocks[Number(groupBlocksKeys[key])];
      if (currentBlock.data.intentType === EIntentType.FlexBlockEntry) {
        entryBlock = currentBlock;
      }
      if (currentBlock.data.intentType === EIntentType.FlexBlockExit)
        exitBlocks.push(currentBlock);
    }

    const dataOutputs: Output[] = [];
    const outputs = {};
    exitBlocks.forEach((exitBlock, index) => {
      Object.assign(outputs, {
        ...outputs,
        [`output_${index + 1}`]: { connections: [] },
      });
      dataOutputs.push({ title: exitBlock.data.name });
    });

    const { editorFlow } = state;
    if (!editorFlow) return;

    const blockName = state.idGroup?.substring(5, state.idGroup?.length) || '';

    const flexBlock: ItemDataFlow = {
      data: {
        name: blockName,
        description: entryBlock.data.description,
        outputs: dataOutputs,
      },
    } as ItemDataFlow;
    return flexBlock;
  };

  const flexGroupTreatment = async () => {
    const modifiedBlock = generateFlexBlockRelatedToGroup();
    if (!modifiedBlock) return;

    await UpdateFlexBlockGroups(
      {
        bot_name: state.botName || '',
        block_name: modifiedBlock.data.name,
        block: modifiedBlock,
      },
      dispatchApp
    );
  };

  const removeFlexBlocksReferences = async () => {
    const groupLastVersion = localStorage.getItem('groupLastVersion') || '';
    const groupLastVersionObj = JSON.parse(groupLastVersion);
    const lastVersionFlexBlocks: RemoveFlexBlock[] = [];
    const currentFlexBlocks: RemoveFlexBlock[] = [];

    const lastVersionBlocksKeys = Object.keys(
      groupLastVersionObj.drawflow.Home.data
    );

    for (const key of lastVersionBlocksKeys) {
      const currentBlock: ItemDataFlow =
        groupLastVersionObj.drawflow.Home.data[key];
      if (currentBlock.data.intentType === EIntentType.FlexBlock) {
        lastVersionFlexBlocks.push({
          block_id: currentBlock.id,
          block_name: currentBlock.data.name,
        });
      }
    }

    const currentVersionBlocksKeys = Object.keys(
      state.editorFlow?.drawflow.drawflow.Home.data
    );

    for (const key of currentVersionBlocksKeys) {
      const currentBlock: ItemDataFlow =
        state.editorFlow?.drawflow.drawflow.Home.data[key];
      if (currentBlock.data.intentType === EIntentType.FlexBlock) {
        currentFlexBlocks.push({
          block_id: currentBlock.id,
          block_name: currentBlock.data.name,
        });
      }
    }

    const removedFlexBlocks = lastVersionFlexBlocks.filter((block) => {
      return !currentFlexBlocks.some((b) => b.block_id === block.block_id);
    });

    if (removedFlexBlocks.length > 0) {
      await DeleteFlexBlock(
        {
          bot_name: state.botName!,
          group_name: state.idGroup ?? '',
          data: removedFlexBlocks,
        },
        dispatchApp
      );
    }
  };

  const saveFlow = async () => {
    try {
      if (
        state.editorFlow?.drawflow &&
        state.botName &&
        state.groupsNames &&
        state.idGroup &&
        state.isEditing &&
        !isEmptyFlow(state)
      ) {
        const botsNames = [...state.botsNames];
        const group = state.groupsNames.find((g) => g === state.idGroup);
        const botName = state.botName;

        if (botName) {
          await removeFlexBlocksReferences();
          const bot = state.bot;

          const currentDrawFlow = state.editorFlow.drawflow.drawflow;
          const currentGroupData = Object.values(currentDrawFlow.Home.data);
          if (
            group === 'principal' &&
            !isValidPrincipalGroup(currentGroupData)
          ) {
            throw new Error(
              'Grupo principal não possui todos os blocos obrigatórios.'
            );
          }

          const resultFlows = await UpdateGroup(
            {
              bot_name: botName,
              group_name: group || '',
              blocks: {
                drawflow: state.editorFlow.drawflow.drawflow,
              },
            },
            dispatchApp
          );

          if (resultFlows.Success) {
            if (bot) {
              bot._id = resultFlows.Data.data._id;
              bot.version = resultFlows.Data.data.version;
            }
            const groupNewVersion = JSON.stringify(state.editorFlow.drawflow);
            localStorage.setItem('groupLastVersion', groupNewVersion);

            dispatch({
              type: 'updateForm',
              data: {
                botsNames,
                drawFlowRef: { ...state.editorFlow.drawflow },
              },
            });

            saveBackupBotGroup({
              dataflow: state.editorFlow?.drawflow ?? {},
              group: group ?? '',
              botName: bot.bot_name,
            });
          } else {
            toastNotification(
              'error',
              resultFlows.Message ||
                'Houve um erro ao tentar salvar a intenção!'
            );
          }
        }
      }
    } catch (error: any) {
      toastNotification(
        'error',
        error.message ?? 'Houve um erro ao tentar salvar a intenção!'
      );
    }
  };

  const exportFlow = async () => {
    var dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(state.editorFlow));

    var dlAnchorElem = document.getElementById('downloadAnchorElem');
    if (dlAnchorElem) {
      dlAnchorElem.setAttribute('href', dataStr);
      dlAnchorElem.setAttribute('download', 'scene.json');
      dlAnchorElem.click();
    }
  };

  const toastNotification = useCallback(
    (type: ToastType, message: string, duration?: number) => {
      dispatch({
        type: 'updateForm',
        data: {
          toastNotification: {
            type,
            message,
            duration,
            show: true,
          },
        },
      });
    },
    []
  );
  const generatePermissions = async (decodedToken: {
    isRoot: boolean;
    companies: IPermissionCompany[];
  }) => {
    const isRoot = decodedToken.isRoot;
    const companies = await getCompanies();

    if (isRoot) {
      const bots = await getBots();
      dispatch({
        type: 'updateForm',
        data: {
          botsNames: bots,
        },
      });
      dispatchPermissions({
        type: 'isRoot',
      });

      dispatchPermissions({
        type: 'updatePermissions',
        data: {
          isRoot,
          companies,
        },
      });
    } else {
      dispatch({
        type: 'updateForm',
        data: {
          botsNames: [],
        },
      });
      dispatchPermissions({
        type: 'isNotRoot',
      });

      dispatchPermissions({
        type: 'updatePermissions',
        data: {
          isRoot,
          companies: decodedToken.companies as IPermissionCompany[],
        },
      });
    }
  };

  const getCompanies = async () => {
    const response = await GetCompanies(dispatchApp);
    if (!response.Success) return [];
    const companies = response.Data as Company[];
    const permissionRot: IPermissionCompany[] = companies.map(
      (comapny): IPermissionCompany => ({
        name: comapny.name,
        isAdmin: true,
        agents: comapny.agents.map((agent) => ({
          actions: ['*'],
          name: agent,
        })),
        projects: comapny.projects!.map((project) => ({
          name: project,
          agents: comapny.agents,
        })),
      })
    );
    return permissionRot;
  };

  const getBots = async () => {
    const response = await GetBotsNames(dispatchApp);
    if (!response.Success) return [];
    return response.Data.data;
  };

  const dispatchNodeToList = (
    nodeToSelection: Element | null,
    headerNodeToSelection: Element | null
  ) => {
    const selectedClassName = 'node-selected';
    const selectedHeaderClassName = 'header-node-selected';

    const included = !!nodeToSelection?.classList.contains(selectedClassName);
    if (!included) {
      nodeToSelection?.classList.add(selectedClassName);
      headerNodeToSelection?.classList.add(selectedHeaderClassName);
    } else {
      nodeToSelection?.classList.remove(selectedClassName);
      headerNodeToSelection?.classList.remove(selectedHeaderClassName);
    }
  };

  const addNodeToList = (block: NodeBlock) => {
    const nodeToSelection = document.querySelector(`#node-${block.nodeId}`);
    const nodeHeaderToSelection = document.querySelector(
      `#header-${block.nodeId}`
    );

    const { editorFlow } = state;

    const children = block.node.data.outputs;
    children?.forEach((output) => {
      if (editorFlow) {
        const { outputid } = output;
        if (outputid !== undefined) {
          const block = editorFlow.drawflow.drawflow.Home.data[outputid];
          if (block) {
            const dataForm: DataForm = block.data;
            const form = new DataFormModel(dataForm);
            if (form.isChild() || form.intentType === 11) {
              const nodeToSelection = document.querySelector(
                `#node-${output.outputid}`
              );
              const nodeHeaderToSelection = document.querySelector(
                `#header-${output.outputid}`
              );
              dispatchNodeToList(nodeToSelection, nodeHeaderToSelection);
            }
          }
        }
      }
    });

    dispatchNodeToList(nodeToSelection, nodeHeaderToSelection);
  };

  const clearNodeList = () => {
    let elements = document.getElementsByClassName('node-selected');
    while (!!elements.length) {
      const elem = elements.item(0);
      elem?.classList.remove('node-selected');
    }
    dispatch({
      type: 'clearNodeList',
    });
  };

  return (
    <FlowContext.Provider
      value={{
        generatePermissions,
        state: state || initialState,
        dispatch,
        addIntent,
        updateIntent,
        mountNodeContent,
        saveFlow,
        exportFlow,
        toastNotification,
        addNodeToList,
        clearNodeList,
        deleteNode,
        loadFlow,
        verifyEditGroup,
        startEditing,
        stopEditing,
        goToNodePosition,
      }}
    >
      {children}
    </FlowContext.Provider>
  );
};

export function useFlow(): IFlowContext {
  const context = useContext(FlowContext);
  return context;
}
