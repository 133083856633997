import React from 'react';
import { Typography } from '@material-ui/core';
import { Child } from 'contexts/Flow/types';
import { GroupChildKeys } from './GroupChildKeys';
import MultipleTags from 'components/inputs/MultipleTags';
import useTranslator from 'utils/hooks/Translator';
import { getTranslationKeys } from 'utils/i18n/types';

export function GroupChild({ items, title }: Child) {
  const { getTranslation } = useTranslator();
  return (
    <React.Fragment>
      {title === 'Tags' ? (
        <>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <MultipleTags hideTitle inColumns noPadding />
        </>
      ) : (
        items.map((item, index) => {
          const translatedTitle = getTranslation(title as getTranslationKeys);
          return (
            <div key={index} style={{ marginBottom: '16px' }}>
              {title === 'outputs' && item.outputs?.hidden ? null : (
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  {translatedTitle}
                </Typography>
              )}
              <GroupChildKeys {...item} />
            </div>
          );
        })
      )}
    </React.Fragment>
  );
}
