import { IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import useTranslator from 'utils/hooks/Translator';
import PublishIcon from '@material-ui/icons/Publish';
import useAgents from 'utils/hooks/useAgents';
import { useState } from 'react';
import { defaultTheme } from 'themes/DefaultTheme';

export function PublishButton() {
  const { getTranslation } = useTranslator();
  const { isAgentFreeToUse, publishAgent } = useAgents();
  const [loadPublish, setLoadPublish] = useState(false);

  const handlePublish = async () => {
    const botInUse = await isAgentFreeToUse(true);
    if (botInUse) {
      if (loadPublish) return;
      setLoadPublish(true);
      await publishAgent();
      setLoadPublish(false);
    }
  };

  return (
    <Tooltip title={getTranslation('menu.publish')} placement="right">
      <IconButton
        onClick={handlePublish}
        style={{
          position: 'absolute',
          bottom: '12px',
          left: '13px',
          background: defaultTheme.palette.secondary.main,
          color: '#fff',
        }}
      >
        {loadPublish ? (
          <CircularProgress color="inherit" size={22} />
        ) : (
          <PublishIcon fontSize="medium" />
        )}
      </IconButton>
    </Tooltip>
  );
}
