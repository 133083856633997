import { IFlowContextState, IFlowContextActions } from './types';

export const initialState = {
  editorFlow: null,
  drawFlowRef: null,
  openModalIntentType: false,
  openModalForms: false,
  openModalFormsNewEditor: false,
  openModalCreateGroup: false,
  openModalSavedBlocks: false,
  openModalSaveSelectedBlocks: false,
  openModalStandardTemplates: false,
  openModalTemplateUpload: false,
  openModalPasswordReset: false,
  dataForm: null,
  toastNotification: {
    type: 'success',
    message: '',
    show: false,
  },
  idGroup: '',
  // bots: [],
  // groups: [],
  intents: [],
  nodesList: {},
  handleNode: null,
  listNodeRemove: [],
  loading: false,

  openModalWarning: false,
  openModalUsedGroup: false,
  openModalBlockedGroup: false,
  openModalAccessKeys: false,
  openModalManageAccount: false,
  openModalManageCompany: false,
  openModalManagePermission: false,

  openModalPhrasesOfIntentManager: false,
  openModalManageCorrectSentences: false,

  openModalCampaignTemplates: false,
  openModalCampaignCampaign: false,
  openModalCampaignActioning: false,

  openModalPublishBot: false,
  openModalFalazapTheme: false,
  openModalFalazapToken: false,
  openModalFlexBlocks: false,

  isEditing: false,
  groupUser: '',
  botsUrls: {
    urlWhatsApp: '',
    urlTelegram: '',
    urlFalazap: '',
    urlFacebook: '',
    urlTwitter: '',
  },
  botsNames: [],
  groupsNames: [],
  bot: {
    _id: '',
    bot_name: '',
    groups: [],
    version: 0,
  },
  userOrganizations: [],
  userPermissions: [],
  showBlockAccessCount: false,
  setAccessCountData: {},
  drawflowToPublish: {},
} as IFlowContextState;

export function reducerFlow(
  state: IFlowContextState,
  action: IFlowContextActions
): IFlowContextState {
  switch (action.type) {
    case 'addFlow':
      return functionsReducer[action.type](state, action.data);
    case 'openModalIntentType':
      return functionsReducer[action.type](state);
    case 'closeModalIntentType':
      return functionsReducer[action.type](state);
    case 'updateForm':
      return functionsReducer[action.type](state, action.data);
    case 'openModalForms':
      return functionsReducer[action.type](state, action.data);
    case 'openModalFormsNewEditor':
      return functionsReducer[action.type](state, action.data);
    case 'closeModalFormsNewEditor':
      return functionsReducer[action.type](state);
    case 'closeModalForms':
      return functionsReducer[action.type](state);
    case 'openModalSavedBlocks':
      return functionsReducer[action.type](state);
    case 'closeModalSavedBlocks':
      return functionsReducer[action.type](state);
    case 'openModalSaveSelectedBlocks':
      return functionsReducer[action.type](state);
    case 'closeModalSaveSelectedBlocks':
      return functionsReducer[action.type](state);
    case 'openModalStandardTemplates':
      return functionsReducer[action.type](state);
    case 'closeModalStandardTemplates':
      return functionsReducer[action.type](state);
    case 'openModalTemplateUpload':
      return functionsReducer[action.type](state);
    case 'closeModalTemplateUpload':
      return functionsReducer[action.type](state);
    case 'openModalCreateGroup':
      return functionsReducer[action.type](state, action.data);
    case 'openModalWarning':
      return functionsReducer[action.type](state, action.data);
    case 'closeModalWarning':
      return functionsReducer[action.type](state, action.data);
    case 'openModalUsedGroup':
      return functionsReducer[action.type](state, action.data);
    case 'closeModalBlockedGroup':
      return functionsReducer[action.type](state, action.data);
    case 'openModalBlockedGroup':
      return functionsReducer[action.type](state, action.data);
    case 'openModalAccessKeys':
      return functionsReducer[action.type](state);
    case 'closeModalAccessKeys':
      return functionsReducer[action.type](state);
    case 'closeModalCreateGroup':
      return functionsReducer[action.type](state);
    case 'closeModalUsedGroup':
      return functionsReducer[action.type](state, action.data);
    case 'openModalManageAccount':
      return functionsReducer[action.type](state);
    case 'closeModalManageAccount':
      return functionsReducer[action.type](state);
    case 'openModalManageCompany':
      return functionsReducer[action.type](state);
    case 'closeModalManageCompany':
      return functionsReducer[action.type](state);
    case 'openModalManagePermission':
      return functionsReducer[action.type](state);
    case 'closeModalManagePermission':
      return functionsReducer[action.type](state);
    case 'openModalPhrasesOfIntentManager':
      return functionsReducer[action.type](state);
    case 'closeModalPhrasesOfIntentManager':
      return functionsReducer[action.type](state);
    case 'openModalManageCorrectSentences':
      return functionsReducer[action.type](state);
    case 'closeModalManageCorrectSentences':
      return functionsReducer[action.type](state);
    case 'clearNodeList':
      return functionsReducer[action.type](state);
    case 'updateNodeToList':
      return functionsReducer[action.type](state, action.data);
    case 'isEditing':
      return functionsReducer[action.type](state, action.data);
    case 'isNotEditing':
      return functionsReducer[action.type](state, action.data);
    case 'showBlockAccessCount':
      return functionsReducer[action.type](state, action.data);
    case 'setAccessCountData':
      return functionsReducer[action.type](state, action.data);
    case 'setIdGroup':
      return functionsReducer[action.type](state, action.data);
    case 'drawflowToPublish':
      return functionsReducer[action.type](state, action.data);
    case 'openModalCampaignTemplates':
      return functionsReducer[action.type](state);
    case 'openModalCampaignCampaign':
      return functionsReducer[action.type](state);
    case 'openModalCampaignActioning':
      return functionsReducer[action.type](state);
    case 'closeModalCampaignTemplates':
      return functionsReducer[action.type](state);
    case 'closeModalCampaignCampaign':
      return functionsReducer[action.type](state);
    case 'closeModalCampaignActioning':
      return functionsReducer[action.type](state);
    case 'openModalPublishBot':
      return functionsReducer[action.type](state);
    case 'closeModalPublishBot':
      return functionsReducer[action.type](state);
    case 'openModalFalazapToken':
      return functionsReducer[action.type](state);
    case 'openModalFalazapTheme':
      return functionsReducer[action.type](state);
    case 'closeModalFalazapToken':
      return functionsReducer[action.type](state);
    case 'closeModalFalazapTheme':
      return functionsReducer[action.type](state);
    case 'openModalFlexBlocks':
      return functionsReducer[action.type](state);
    case 'closeModalFlexBlocks':
      return functionsReducer[action.type](state);
    default:
      return state;
  }
}

const functionsReducer = {
  addFlow: (state: IFlowContextState, data?: Partial<IFlowContextState>) => {
    return { ...state, editorFlow: data?.editorFlow || state.editorFlow };
  },

  setAccessCountData: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, setAccessCountData: data?.setAccessCountData };
  },

  showBlockAccessCount: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, showBlockAccessCount: data?.showBlockAccessCount };
  },

  setIdGroup: (state: IFlowContextState, data?: Partial<IFlowContextState>) => {
    return { ...state, idGroup: data?.idGroup };
  },

  isEditing: (state: IFlowContextState, data?: Partial<IFlowContextState>) => {
    return { ...state, isEditing: true };
  },

  isNotEditing: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, isEditing: false };
  },

  openModalBlockedGroup: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, openModalBlockedGroup: true };
  },

  closeModalUsedGroup: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, openModalUsedGroup: false };
  },

  openModalUsedGroup: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, openModalUsedGroup: true };
  },

  closeModalBlockedGroup: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, openModalBlockedGroup: false };
  },

  openModalIntentType: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, openModalIntentType: true };
  },

  closeModalIntentType: (state: IFlowContextState) => {
    return { ...state, openModalIntentType: false };
  },

  updateForm: (state: IFlowContextState, data?: Partial<IFlowContextState>) => {
    return { ...state, ...data };
  },

  openModalForms: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return {
      ...state,
      openModalForms: true,
      dataForm: data?.dataForm || null,
      nodeId: data?.nodeId,
    };
  },

  openModalFormsNewEditor: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return {
      ...state,
      openModalFormsNewEditor: true,
      dataForm: data?.dataForm || null,
      nodeId: data?.nodeId,
    };
  },

  closeModalForms: (state: IFlowContextState) => {
    return { ...state, openModalForms: false };
  },

  closeModalFormsNewEditor: (state: IFlowContextState) => {
    return { ...state, openModalFormsNewEditor: false };
  },

  openModalSavedBlocks: (state: IFlowContextState) => {
    return { ...state, openModalSavedBlocks: true };
  },

  closeModalSavedBlocks: (state: IFlowContextState) => {
    return { ...state, openModalSavedBlocks: false };
  },

  openModalSaveSelectedBlocks: (state: IFlowContextState) => {
    return { ...state, openModalSaveSelectedBlocks: true };
  },

  closeModalSaveSelectedBlocks: (state: IFlowContextState) => {
    return { ...state, openModalSaveSelectedBlocks: false };
  },

  openModalStandardTemplates: (state: IFlowContextState) => {
    return { ...state, openModalStandardTemplates: true };
  },

  closeModalStandardTemplates: (state: IFlowContextState) => {
    return { ...state, openModalStandardTemplates: false };
  },

  openModalTemplateUpload: (state: IFlowContextState) => {
    return { ...state, openModalTemplateUpload: true };
  },

  closeModalTemplateUpload: (state: IFlowContextState) => {
    return { ...state, openModalTemplateUpload: false };
  },

  openModalAccessKeys: (state: IFlowContextState) => {
    return { ...state, openModalAccessKeys: true };
  },

  closeModalAccessKeys: (state: IFlowContextState) => {
    return { ...state, openModalAccessKeys: false };
  },

  openModalManageCompany: (state: IFlowContextState) => {
    return { ...state, openModalManageCompany: true };
  },

  closeModalManageCompany: (state: IFlowContextState) => {
    return { ...state, openModalManageCompany: false };
  },

  openModalManagePermission: (state: IFlowContextState) => {
    return { ...state, openModalManagePermission: true };
  },

  closeModalManagePermission: (state: IFlowContextState) => {
    return { ...state, openModalManagePermission: false };
  },

  openModalManageAccount: (state: IFlowContextState) => {
    return { ...state, openModalManageAccount: true };
  },

  closeModalManageAccount: (state: IFlowContextState) => {
    return { ...state, openModalManageAccount: false };
  },

  openModalPhrasesOfIntentManager: (state: IFlowContextState) => {
    return { ...state, openModalPhrasesOfIntentManager: true };
  },

  closeModalPhrasesOfIntentManager: (state: IFlowContextState) => {
    return { ...state, openModalPhrasesOfIntentManager: false };
  },

  openModalManageCorrectSentences: (state: IFlowContextState) => {
    return { ...state, openModalManageCorrectSentences: true };
  },

  closeModalManageCorrectSentences: (state: IFlowContextState) => {
    return { ...state, openModalManageCorrectSentences: false };
  },

  openModalCorrectSentences: (state: IFlowContextState) => {
    return { ...state, openModalCorrectSentences: true };
  },

  closeModalCorrectSentences: (state: IFlowContextState) => {
    return { ...state, openModalCorrectSentences: false };
  },

  openModalCampaignTemplates: (state: IFlowContextState) => {
    return { ...state, openModalCampaignTemplates: true };
  },

  openModalCampaignCampaign: (state: IFlowContextState) => {
    return { ...state, openModalCampaignCampaign: true };
  },

  openModalCampaignActioning: (state: IFlowContextState) => {
    return { ...state, openModalCampaignActioning: true };
  },
  closeModalCampaignTemplates: (state: IFlowContextState) => {
    return { ...state, openModalCampaignTemplates: false };
  },

  closeModalCampaignCampaign: (state: IFlowContextState) => {
    return { ...state, openModalCampaignCampaign: false };
  },

  closeModalCampaignActioning: (state: IFlowContextState) => {
    return { ...state, openModalCampaignActioning: false };
  },

  openModalWarning: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, openModalWarning: true };
  },

  closeModalWarning: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, openModalWarning: false };
  },

  openModalCreateGroup: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, openModalCreateGroup: true };
  },

  closeModalCreateGroup: (state: IFlowContextState) => {
    return { ...state, openModalCreateGroup: false };
  },

  openModalFalazapToken: (state: IFlowContextState) => {
    return { ...state, openModalFalazapToken: true };
  },

  openModalFalazapTheme: (state: IFlowContextState) => {
    return { ...state, openModalFalazapTheme: true };
  },
  closeModalFalazapToken: (state: IFlowContextState) => {
    return { ...state, openModalFalazapToken: false };
  },

  closeModalFalazapTheme: (state: IFlowContextState) => {
    return { ...state, openModalFalazapTheme: false };
  },

  clearNodeList: (state: IFlowContextState) => {
    return { ...state, nodesList: {} };
  },

  openModalPublishBot: (state: IFlowContextState) => {
    return { ...state, openModalPublishBot: true };
  },

  closeModalPublishBot: (state: IFlowContextState) => {
    return { ...state, openModalPublishBot: false };
  },

  openModalFlexBlocks: (state: IFlowContextState) => {
    return { ...state, openModalFlexBlocks: true };
  },

  closeModalFlexBlocks: (state: IFlowContextState) => {
    return { ...state, openModalFlexBlocks: false };
  },

  drawflowToPublish: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    return { ...state, drawflowToPublish: data };
  },

  updateNodeToList: (
    state: IFlowContextState,
    data?: Partial<IFlowContextState>
  ) => {
    if (data?.nodesList) {
      const nodesList = { ...state.nodesList };
      for (const key in data.nodesList) {
        if (!!nodesList[key]) {
          delete nodesList[key];
        } else {
          nodesList[key] = data.nodesList[key];
        }
      }
      return {
        ...state,
        nodesList,
      };
    }
    return state;
  },
};
