// import { useCallback, useEffect, useState } from 'react';

import * as S from './styles';

import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import { IBlockAccessCountProps } from './types';

import { MetaBlockType } from 'contexts/Flow/types';
import ModalBlockOptions from '..';
import FlagsContainer from '../BlockAccessCount/FlagsContainer';

function MetadataBlock(props: IBlockAccessCountProps) {
  const {
    // blocksData,
    name,
    nodeId,
    node,
    state,
    dispatch,
    addNodeToList,
    clearNodeList,
    deleteNode,
    duplicateNode,
    form,
    toastNotification,
  } = props;

  const metadataType = form.metadata?.type ?? 'Api';

  const getMetaBlockColor: { [key in MetaBlockType]: string } = {
    Question: '#3e7000',
    Message: '#393d8f',
    Multiple: '#03534b',
    Api: '#5e5d5d',
		SimpleCarousel: '#6b4492',
    Carousel: '#6b4492',
    Logic: '#7a5531',
    Entry: '#aa0a0a',
    ToAnotherBlock: '#5e5d5d',
    GetDocument: '#084d07',
    ValidateCPF: '#5e5d5d',
    ValidateCNPJ: '#5e5d5d',
    Notification: '#750241',
    Conditional: '#5e5d5d',
    ABTest: '#5a0791',
    '123AI': '#d4783f',
		Advanced: '#88024c'
  };

  return (
    <S.Content id={`header-${nodeId}`} color={getMetaBlockColor[metadataType]}>
      <S.CountContainer>{nodeId}</S.CountContainer>
      <S.NameContainer>
        <DynamicFeedIcon />
        <div title={name}>
          <span>{name}</span>
        </div>
      </S.NameContainer>

      <S.RightContentWrapper>
        <FlagsContainer
          state={state}
          dispatch={dispatch}
          form={form}
          node={nodeId}
          toast={toastNotification}
        />

      <S.ConfigContainer>
        <ModalBlockOptions
          node={node}
          nodeId={nodeId}
          state={state}
          dispatch={dispatch}
          addNodeToList={addNodeToList}
          clearNodeList={clearNodeList}
          deleteNode={deleteNode}
          duplicateNode={duplicateNode}
          form={form}
        />
      </S.ConfigContainer>
      </S.RightContentWrapper>
    </S.Content>
  );
}

export default MetadataBlock;
