import React, { createContext, useCallback, useContext, useState } from 'react';
import { AirTalkContextState, Props } from './types';
import { useFlow } from 'contexts/Flow/flowContext';
import GptToDrawflowFormatter from 'utils/gptToDrawflowFormatter';
import { DataFlow } from 'models/DataFlow';
import { fixInputsAndOutputs } from 'components/Flow/utils/NodeContainer';
import { DataFlowObject } from 'contexts/Flow/types';

const AirTalkContext = createContext<AirTalkContextState>(
  {} as AirTalkContextState
);

export function AirTalkContextProvider({ children }: Props) {
  const { state, mountNodeContent, goToNodePosition } = useFlow();
  const [loading, setLoading] = useState(false);

  const handleChangeFlow = useCallback(
    (newNodes) => {
      if (!state.editorFlow) return;
      const { updatedEditorFlow, firstNodeOfNewNodesId } =
        GptToDrawflowFormatter(state.editorFlow, newNodes);

      const drawflow = updatedEditorFlow.drawflow.drawflow as DataFlowObject;

      try {
        state.editorFlow?.import({ drawflow });
      } catch (e) {
        const fixedDrawflow = fixInputsAndOutputs(drawflow);

        try {
          state.editorFlow?.import(fixedDrawflow);
        } catch (e) {
          console.log(e);
        }
      }

      const dataFlow: DataFlow = state?.editorFlow?.drawflow.drawflow.Home.data;
      mountNodeContent(dataFlow);
      goToNodePosition(firstNodeOfNewNodesId);
    },
    [goToNodePosition, mountNodeContent, state.editorFlow]
  );

  const generateText = useCallback(
    async (promptToUse: string, sendToFlow = false): Promise<string> => {
      if (!promptToUse) return '';
      setLoading(true);
      try {
        const response = await fetch(
          `https://api-biz-hub-dev.fintalk.io/generate-text?text=${promptToUse}`
        );
        const data = await response.json();
        console.log(JSON.parse(data.generatedText));
        if (sendToFlow) handleChangeFlow(JSON.parse(data.generatedText));
        setLoading(false);

        return data.generatedText;
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
      setLoading(false);

      return '';
    },
    [handleChangeFlow]
  );

  return (
    <AirTalkContext.Provider
      value={{
        loading,
        generateText,
      }}
    >
      {children}
    </AirTalkContext.Provider>
  );
}

export function useAirTalk() {
  const context = useContext(AirTalkContext);
  return context;
}
