import {
  Beenhere,
  // Business,
  Flag,
  Forum,
  GetApp,
  ImportExport,
  Publish,
  Restore,
  Stars,
  VpnKey,
  Widgets,
} from '@material-ui/icons';

import ActioningIcon from 'components/Icons/ActioningIcon';
import CampaignIcon from 'components/Icons/CampaignIcon';
import SchemaIcon from 'components/Icons/SchemaIcon';
import TemplateIcon from 'components/Icons/TemplateIcon';
import VariablesIcon from 'components/Icons/VariablesIcon';
import { SidebarOption } from 'contexts/FintalkCloud/types';
import useTranslator from './Translator';
// import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CodeIcon from '@material-ui/icons/Code';

export function useFintalkCloudSidebarItems() {
  const { getTranslation } = useTranslator();

  const listLibrary: Array<SidebarOption> = [
    {
      canBeUsed: ['flow:write'],
      name: getTranslation('menu.libraryChildren.defaultTemplates'),
      icon: <Flag />,
      builderElementToOpen: 'standartTemplates',
      needsAgentName: true,
    },
    {
      canBeUsed: ['flow:write'],
      name: getTranslation('menu.libraryChildren.savedBlocks'),
      icon: <Widgets />,
      builderElementToOpen: 'savedBlocks',
      needsAgentName: true,
    },
    {
      canBeUsed: ['flow:write'],
      name: getTranslation('menu.libraryChildren.saveSelectedBlocks'),
      icon: <Beenhere />,
      builderElementToOpen: 'saveSelectedBlocks',
      needsAgentName: true,
    },
  ];

  const listImporExportBot: Array<SidebarOption> = [
    {
      canBeUsed: ['flow:publish'],
      name: getTranslation('menu.uploadDownloadAgentChildren.upload'),
      icon: <Publish />,
      needsAgentName: true,
      action: 'importAgent',
    },
    {
      canBeUsed: ['flow:publish'],
      name: getTranslation('menu.uploadDownloadAgentChildren.download'),
      icon: <GetApp />,
      needsAgentName: true,
      action: 'exportAgent',
    },
  ];

  const campaignSidebar: SidebarOption[] = [
    {
      canBeUsed: ['flow:publish'],
      icon: <TemplateIcon />,
      name: 'Templates',
      needsAgentName: true,
      campaignElement: 'templates',
    },
    {
      canBeUsed: ['access_key:read', 'access_key:write'],
      icon: <CampaignIcon />,
      name: getTranslation('campaign'),
      needsAgentName: true,
      campaignElement: 'campaigns',
    },
    {
      canBeUsed: ['access_key:read', 'access_key:write'],
      icon: <ActioningIcon />,
      name: getTranslation('actionings'),
      needsAgentName: true,
      campaignElement: 'actionings',
    },
  ];

  const crmSidebar: SidebarOption[] = [
    {
      canBeUsed: ['flow:read', 'flow:write'],
      icon: <AccountCircleIcon />,
      name: 'Customers',
      needsAgentName: true,
      crmElement: 'customers',
    },
    {
      canBeUsed: ['flow:read', 'flow:write'],
      icon: <CodeIcon />,
      name: 'Custom Params',
      needsAgentName: true,
      crmElement: 'customParams',
    },
  ];

  const homeSidebar: SidebarOption[] = [
    {
      canBeUsed: ['flow:publish'],
      icon: <Restore />,
      name: getTranslation('menu.publishHistory'),
    },
    {
      canBeUsed: ['access_key:read', 'access_key:write'],
      icon: <VpnKey />,
      name: getTranslation('menu.editAccessKeys'),
    },
  ];

  const builderSidebarOptions: SidebarOption[] = [
    {
      canBeUsed: ['flow:read', 'flow:write'],
      icon: <SchemaIcon />,
      name: getTranslation('menu.flows'),
      needsAgentName: true,
    },
    {
      canBeUsed: ['flow:write'],
      icon: <VariablesIcon />,
      name: getTranslation('menu.variables'),
      builderElementToOpen: 'entities',
      needsAgentName: true,
    },
    {
      canBeUsed: ['flow:write'],
      icon: <Stars />,
      name: getTranslation('menu.library'),
      children: listLibrary,
      needsAgentName: true,
      needsEdition: true,
    },
    {
      canBeUsed: ['flow:read', 'flow:publish', 'flow:write'],
      icon: <Forum />,
      name: getTranslation('menu.conversationHistory'),
      builderElementToOpen: 'conversationHistory',
      needsAgentName: true,
    },
    {
      canBeUsed: ['flow:publish'],
      icon: <ImportExport />,
      name: getTranslation('menu.uploadDownloadAgent'),
      children: listImporExportBot,
      needsAgentName: true,
    },
    {
      canBeUsed: ['flow:publish'],
      icon: <Restore />,
      name: getTranslation('menu.publishHistory'),
      builderElementToOpen: 'publishHistory',
      needsAgentName: true,
    },
    {
      canBeUsed: ['access_key:read', 'access_key:write'],
      icon: <VpnKey />,
      name: getTranslation('menu.editAccessKeys'),
      builderElementToOpen: 'keys',
      needsAgentName: true,
    },
  ];

  return {
    listLibrary,
    listImporExportBot,
    campaignSidebar,
    homeSidebar,
    builderSidebarOptions,
    crmSidebar,
  };
}
