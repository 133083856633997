import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { DashboardOptions } from 'contexts/FintalkCloud/types';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useEffect, useMemo, useState } from 'react';
import { useStyles } from './styles';

export default function FintalkCloudHeader() {
  const classes = useStyles();
  const { isCompanyAdmin, isRoot } = usePermissions();
  const { handleChangeCurrentElementName, currentData, currentElementName } =
    useFintalkCloud();
  const [value, setValue] = useState(0);

  const optionsList = useMemo(() => {
    return [
      // { index: 0, key: 'home', canBeUsed: true },
      // { index: 3, key: 'desk', canBeUsed: true },
      // { index: 4, key: 'automation', canBeUsed: true },
      {
        index: 0,
        prettyName: 'builder',
        key: 'builder',
        canBeUsed: currentData.companyName && currentData.projectName,
      },
      { index: 1, prettyName: 'campaign', key: 'campaign', canBeUsed: true },
      {
        index: 2,
        prettyName: 'airtalk (beta)',
        key: 'airtalk',
        canBeUsed: currentData.companyName && currentData.projectName,
      },
      // {
      //   index: 3,
      //   prettyName: 'crm',
      //   key: 'crm',
      //   canBeUsed: currentData.companyName && currentData.projectName,
      // },
      {
        index: 3,
        prettyName: 'admin',
        key: 'admin',
        canBeUsed: isCompanyAdmin(currentData.companyName) || isRoot(),
      },
    ];
  }, [
    currentData.companyName,
    currentData.projectName,
    isCompanyAdmin,
    isRoot,
  ]);

  useEffect(() => {
    setValue(
      optionsList.find((option) => option.key === currentElementName)?.index ||
        0
    );
  }, [currentElementName, optionsList]);

  function getElementName(value: number) {
    return optionsList[value];
  }

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
    handleChangeCurrentElementName(
      getElementName(newValue).key as DashboardOptions
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.customTab}
          centered
        >
          {optionsList.map((option) => (
            <Tab
              key={option.index}
              label={option.prettyName}
              disabled={!option.canBeUsed}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
