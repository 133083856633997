/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import { useFlow } from 'contexts/Flow/flowContext';
import Drawflow from 'drawflow';
import React, { useEffect, useState } from 'react';
import './styles.css';

import ModalBlockedGroup from 'components/ModalBlockedGroup';
import ModalInactiveWarning from 'components/ModalInactiveWarning';
import ModalUsedGroup from 'components/ModalUsedGroup';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { DataFlowObject } from 'contexts/Flow/types';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import RemoveIntentController from 'controllers/intent/RemoveIntentController';
import EIntentType, { validateBlockedIntents } from 'enums/EIntentType';
import { DataFlow, DataForm } from 'models/DataFlow';
import DataFormModel from 'models/DataFormModel';
import { DrawflowModel } from 'models/DrawflowModel';
import useTranslator from 'utils/hooks/Translator';
import LoadWrapper from '../LoadWrapper';
import ModalOptions from '../ModalOptions';
import FilterField from './FilterField';
import {
  fixInputs,
  fixInputsAndOutputs,
  fixOutputs,
  getNodePosition,
  getSelectedNodeId,
  overrideDrag,
  selectAllNodes,
  uncheckSelectNodes,
} from './utils/NodeContainer';
import NodeSelection from './utils/NodeSelection';

const FlowComponent: React.FC = () => {
  const { currentData } = useFintalkCloud();
  const {
    dispatch,
    mountNodeContent,
    state,
    toastNotification,
    verifyEditGroup,
  } = useFlow();
  const [isDeleteNode, setIsDeleteNode] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const { hasPermissionToAction } = usePermissions();

  const [anchorEl, setAnchorEl] = useState(null);
  const [importFile, setImportFile] = useState(false);
  const [exportFile, setExportFile] = useState(false);
  const { getTranslation } = useTranslator();

  let selectNodes = false;

  const isFlowWrite = hasPermissionToAction({
    company: state.companyName!,
    agent: state.botName!,
    action: ['flow:write'],
  });

  useEffect(() => {
    if (state) {
      const documentHtml = document.getElementById('drawflow');
      NodeSelection(documentHtml);
    }
  }, [state]);

  useEffect(() => {
    var documentHtml = document.getElementById('drawflow');
    if (documentHtml) {
      const editorFlow = new Drawflow(documentHtml);
      overrideDrag(editorFlow);
      editorFlow.start();

      editorFlow.on('nodeRemoved', function (node) {
        const drawflow = editorFlow.drawflow.drawflow.Home.data;
        const allNodesKeys = Object.keys(drawflow);

        for (const key of allNodesKeys) {
          if (drawflow[Number(key)]) {
            const inputs = drawflow[Number(key)].inputs;
            const outputs = drawflow[Number(key)].outputs;

            if (inputs && inputs.input_1) {
              const inputConnections = inputs.input_1.connections;

              if (inputConnections) {
                const connectionKeys = Object.keys(inputConnections);

                for (const conKey of connectionKeys) {
                  if (inputConnections[Number(conKey)]) {
                    const conNode = String(
                      inputConnections[Number(conKey)].node
                    );
                    if (conNode) {
                      if (conNode === String(node)) {
                        inputConnections.splice(Number(conKey), 1);
                      }
                    }
                  }
                }
              }
            }

            if (outputs && typeof outputs === 'object') {
              const outputsKeys = Object.keys(outputs);

              for (const output in outputsKeys) {
                const connectionsKeys = Object.keys(
                  outputs[outputsKeys[Number(output)]].connections
                );
                for (const key of connectionsKeys) {
                  if (
                    outputs[outputsKeys[Number(output)]].connections[
                      Number(key)
                    ].node === node
                  )
                    outputs[outputsKeys[Number(output)]].connections.splice(
                      Number(key),
                      1
                    );
                }
              }
            }
          }
        }
      });

      editorFlow.on('connectionCreated', function (connection) {
        const { input_class, input_id, output_class, output_id } = connection;
        const block = editorFlow.drawflow.drawflow.Home.data[output_id];

        const destinationBlock =
          editorFlow.drawflow.drawflow.Home.data[input_id];

        if (
          block.data.intentType !== 1 &&
          block.data.intentType !== 5 &&
          block.outputs.output_1.connections.length > 1
        ) {
          if (
            Number(block.outputs.output_1.connections[0].node) ===
            Number(block.outputs.output_1.connections[1].node)
          ) {
            block.outputs[output_class].connections.splice(0, 1);
          } else {
            editorFlow.removeSingleConnection(
              output_id,
              input_id,
              output_class,
              input_class
            );
          }
          return;
        }

        const amountConnections =
          block.outputs[output_class].connections.length;

        if (
          destinationBlock.data.parentId !== block.id &&
          (amountConnections > 1 ||
            destinationBlock.data.intentType === 2 ||
            destinationBlock.data.intentType === 6 ||
            destinationBlock.data.intentType === 11)
        ) {
          editorFlow.removeSingleConnection(
            output_id,
            input_id,
            output_class,
            input_class
          );
        }
      });
      dispatch({ type: 'addFlow', data: { editorFlow } });
    }
  }, [dispatch]);

  useEffect(() => {
    if (isDeleteNode) {
      if (state.editorFlow && state.editorFlow.editor_mode === 'edit') {
        const ids = getSelectedNodeId();
        const currGroup = JSON.stringify(state.editorFlow?.drawflow);
        localStorage.setItem('last_updated', currGroup);
        const intentController = new RemoveIntentController(state.editorFlow);
        const parentIds = [];

        for (const id of ids) {
          const dataForm: DataForm =
            state.editorFlow.drawflow.drawflow.Home.data[id].data;
          const form = new DataFormModel(dataForm);
          if (
            !form.isChild() &&
            !form.isOthers() &&
            !form.isMain() &&
            form.intentType !== EIntentType.Welcome
          ) {
            parentIds.push(id);
          }
        }

        for (const parentId of parentIds) {
          intentController.removeNodeParent(parentId);
        }
      }
      if (state.openModalFormsNewEditor)
        dispatch({
          type: 'closeModalFormsNewEditor',
        });
      setIsDeleteNode(false);
    }
  }, [isDeleteNode, state.editorFlow]);

  useEffect(() => {
    const documentHtml = document.getElementById('drawflow');
    if (documentHtml) {
      documentHtml.addEventListener(
        'keydown',
        (e: KeyboardEvent) => {
          if (e.key === 'Delete' || (e.key === 'Backspace' && e.metaKey)) {
            keyboardEventDefault(e);
            setIsDeleteNode(true);
          } else if (e.key === 's' && e.ctrlKey === true) {
            exportFileDefault(e);
          } else if (e.key === 'f' && e.ctrlKey === true) {
            keyboardEventDefault(e);
            handleOpenFilter();
          } else if (e.key === 'a' && e.ctrlKey === true) {
            handleSelectNodes();
          } else if (e.key === 'z' && e.ctrlKey === true) {
            // setRedo(true);
          } else {
            if (e.key === 'l' && e.ctrlKey === true) {
              importFileDefault(e);
            }
          }
        },
        true
      );
    }
  }, []);

  useEffect(() => {
    if (exportFile) {
      const documentHtml = document.getElementById('drawflow');
      const dataStr =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(state.editorFlow?.drawflow));
      const dlAnchorElem = document.createElement('a');
      documentHtml?.appendChild(dlAnchorElem);
      if (dlAnchorElem) {
        dlAnchorElem.setAttribute('href', dataStr);
        const dateGenerated = new Date();
        const dataFormated =
          dateGenerated.getDate() +
          '-' +
          dateGenerated.getMonth() +
          '-' +
          dateGenerated.getFullYear() +
          '-' +
          dateGenerated.getHours() +
          '-' +
          dateGenerated.getMinutes();
        dlAnchorElem.setAttribute(
          'download',
          `dataflow-${state.idGroup}-${dataFormated}.json`
        );
        dlAnchorElem.click();
        dlAnchorElem.parentNode?.removeChild(dlAnchorElem);
      }
      setExportFile(false);
    }
  }, [exportFile, state.editorFlow?.drawflow, state.idGroup]);

  useEffect(() => {
    if (importFile) {
      const documentHtml = document.getElementById('drawflow');
      const uploadInput = document.createElement('input');
      uploadInput.type = 'file';
      uploadInput.accept = 'application/json';
      uploadInput.onchange = async (e: any) => {
        const file = e.target.files?.item(0);
        const drawflow = JSON.parse(await file.text());

        if (drawflow.drawflow) {
          if (drawflow.drawflow.Home) {
            const dataFlow: DataFlow = drawflow.drawflow.Home.data;

            const keys = Object.keys(dataFlow);
            let hasInitialBlocks = false;
            const isPrincipalGroup = state.idGroup === 'principal';

            try {
              for (const key of keys) {
                const { data } = dataFlow[Number(key)];

                if (isPrincipalGroup) {
                  if (validateBlockedIntents(data.intentType)) {
                    hasInitialBlocks = true;
                    break;
                  }
                } else if (validateBlockedIntents(data.intentType)) {
                  throw new Error(
                    getTranslation('toast.error.onlyValidForPrincipal')
                  );
                }

                dataFlow[Number(key)].id = Number(key);
                fixInputs(dataFlow[Number(key)], dataFlow);
                fixOutputs(dataFlow[Number(key)], dataFlow);

                const sPayload = data.dataBlockly?.payload as string;
                if (sPayload) {
                  const newPayload = sPayload.replaceAll(
                    `${data.groupId}_`, //nome atual do grupo
                    `${state.idGroup}_` //novo nome do grupo
                  );

                  if (data.dataBlockly?.payload)
                    data.dataBlockly.payload = newPayload;
                }
              }

              if (isPrincipalGroup && !hasInitialBlocks) {
                throw new Error(
                  getTranslation('toast.error.invalidFileForPrincipal')
                );
              }
            } catch (error: any) {
              toastNotification('error', error.message);
              return;
            }

            const drawflowModel = new DrawflowModel(
              dataFlow,
              state.idGroup || ''
            );

            drawflow.drawflow.Home.data = drawflowModel.getDrawflow();

            tryToImportFlow(drawflow);

            mountNodeContent(dataFlow);
            uploadInput.parentNode?.removeChild(uploadInput);

            const block = state.editorFlow?.drawflow.drawflow.Home.data;
            const blocksKeys = Object.keys(block);
            const positionsX = blocksKeys.map(
              (key) => block[Number(key)].pos_x
            );

            const biggestPos_x = Math.min(...positionsX);

            const minBlock = blocksKeys.find(
              (key) => block[Number(key)].pos_x === biggestPos_x
            );

            const { x, y } = getNodePosition(minBlock as unknown as number);

            const newX = x / 2 + x * 0.1;
            const newY = y / 2 + y * 0.1;

            if (state.editorFlow) {
              state.editorFlow.canvas_x = -newX;
              state.editorFlow.canvas_y = -newY;
              if (state.editorFlow.precanvas) {
                state.editorFlow.precanvas.style.transform = `translate(${state.editorFlow.canvas_x}px, 
                ${state.editorFlow.canvas_y}px) 
                scale(${state.editorFlow.zoom}) `;
              }
            }
          } else {
            toastNotification(
              'error',
              getTranslation('toast.error.invalidFileForGroup')
            );
          }
        } else {
          toastNotification(
            'error',
            getTranslation('toast.error.invalidFileForGroup')
          );
        }
      };
      documentHtml?.appendChild(uploadInput);
      uploadInput.click();
      uploadInput.parentNode?.removeChild(uploadInput);
      setImportFile(false);
    }
  }, [importFile, mountNodeContent, state.editorFlow, state.idGroup]);

  const tryToImportFlow = (drawflow: DataFlowObject) => {
    try {
      state.editorFlow?.import(drawflow);
    } catch (e) {
      const fixedDrawflow = fixInputsAndOutputs(drawflow);
      tryToImportFlow(fixedDrawflow);
    }
  };

  const handleSelectNodes = () => {
    selectNodes = !selectNodes;
    if (selectNodes) {
      selectAllNodes();
    } else {
      uncheckSelectNodes();
    }
  };

  const importFileDefault = (e?: KeyboardEvent) => {
    const button = document.getElementById(
      'import-button'
    ) as HTMLButtonElement;
    if (button) {
      const disabled = button.disabled;

      if (!disabled) {
        keyboardEventDefault(e);
        setImportFile(true);
      }
    }
  };

  const exportFileDefault = (e?: KeyboardEvent) => {
    keyboardEventDefault(e);
    setExportFile(true);
  };

  const keyboardEventDefault = (e?: KeyboardEvent) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();
  };

  const handleZoomAll = () => {
    if (state.editorFlow) {
      if (state.editorFlow.zoom < 0.6) {
        handleZoomIn();
        handleZoomAll();
      } else if (state.editorFlow.zoom > 0.7) {
        handleZoomOut();
        handleZoomAll();
      }
    }
  };

  const handleZoomIn = () => {
    if (state.editorFlow) {
      state.editorFlow.zoom_in();
    }
  };

  const handleZoomOut = () => {
    if (state.editorFlow) {
      state.editorFlow.zoom_out();
    }
  };

  const handleOpenFilter = () => {
    setShowFilter(true);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleEditGroup = () => {
    if (
      !state.isEditing &&
      !!currentData.agentName &&
      !!currentData.groupName &&
      !!isFlowWrite
    ) {
      verifyEditGroup(
        currentData.groupName || '',
        currentData?.agentName || ''
      );
    }
  };

  const addIntentElement = () => {
    return (
      <div className="add-intent">
        <Tooltip title={getTranslation('addIntent')} placement="right">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(e: any) => {
              dispatch({ type: 'openModalIntentType' });
              setAnchorEl(e.currentTarget);
            }}
            size={'small'}
            style={{ width: '56px', height: '56px' }}
          >
            <AddIcon style={{ color: '#fff' }} />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      {currentData.groupName && (
        <div className="container-zoom">
          <Tooltip
            title={getTranslation('tooltip.searchIntent')}
            placement="right"
          >
            <IconButton aria-label="filter-list" onClick={handleOpenFilter}>
              <SearchIcon htmlColor="#fff" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={getTranslation('tooltip.flowStart')}
            placement="right"
          >
            <IconButton
              aria-label="zoom_all"
              onClick={() => {
                state.editorFlow?.changeModule('Home');
                if (state.editorFlow?.drawflow.drawflow.Home.data) {
                  mountNodeContent(
                    state.editorFlow.drawflow.drawflow.Home.data
                  );
                }
                handleZoomAll();
              }}
            >
              <MyLocationIcon htmlColor="#fff" />
            </IconButton>
          </Tooltip>

          <Tooltip title={getTranslation('tooltip.lessZoom')} placement="right">
            <IconButton aria-label="zoom_out" onClick={handleZoomOut}>
              <RemoveCircleOutlineIcon htmlColor="#fff" />
            </IconButton>
          </Tooltip>

          <Tooltip title={getTranslation('tooltip.moreZoom')} placement="right">
            <IconButton aria-label="zoom_in" onClick={handleZoomIn}>
              <AddCircleOutlineIcon htmlColor="#fff" />
            </IconButton>
          </Tooltip>
        </div>
      )}

      {state.isEditing && addIntentElement()}

      {!state.isEditing && <ModalBlockedGroup />}
      <div
        id="drawflow"
        onDoubleClick={handleEditGroup}
      ></div>
      {showFilter && <FilterField handleClose={handleCloseFilter} />}
      <LoadWrapper loading={state.loading} />
      {state.openModalIntentType && <ModalOptions anchorEl={anchorEl} />}

      <ModalInactiveWarning />
      <ModalUsedGroup />
    </>
  );
};

export default FlowComponent;
