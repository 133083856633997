import { useCallback, useState } from 'react';
import { GetGroupsInUse } from 'services/FlowService';
import useTranslator from './Translator';
import jwt_decode from 'jwt-decode';
import { useFlow } from 'contexts/Flow/flowContext';
import { useApp } from 'contexts/App/appContext';
import createBotObject from 'utils/CreateBotObject';
import { GetFlows, SavePublishBackup } from 'services/BotService';
import { DrawflowModel } from 'models/DrawflowModel';
import { compileBot } from 'components/BlocklyConstructor/compiler';
import { IEntity } from 'models/Entity';
import { IFlow } from 'services/BotService/types';
import compileFlow from 'components/Flow/utils/CompileFlow';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import tagsCompiler from 'utils/tagCompiler';

export default function useAgents() {
  const { currentData } = useFintalkCloud();
  const { state, toastNotification, saveFlow, dispatch } = useFlow();
  const { dispatch: dispatchApp } = useApp();
  const { agentName } = currentData;
  const { getTranslation } = useTranslator();
  const [botDrawflow, setBotDrawflow] = useState<any>();

  const isAgentFreeToUse = useCallback(
    async (publish = false) => {
      const status = publish
        ? getTranslation('agentOrGroupStatus.published')
        : getTranslation('agentOrGroupStatus.updated');

      const token = localStorage.getItem('token');

      if (token) {
        const { email }: any = jwt_decode(token);
        const regExp = /^[^@]*/;
        const regexEmailResult = regExp.exec(email[0]);
        if (regexEmailResult) {
          const regexEmailMatch = regexEmailResult[0];

          if (agentName) {
            const response = await GetGroupsInUse(agentName, dispatchApp);
            const groupsInUse = response.Data.data;

            if (!!groupsInUse.length) {
              const usersOtherThanCurrent: {
                user: string;
                group: string;
              }[] = [];

              groupsInUse.forEach((group) => {
                const regexUserResult = regExp.exec(group.current_user);
                if (regexUserResult) {
                  const regexUserMatch = regexUserResult[0];

                  if (regexUserMatch !== regexEmailMatch) {
                    usersOtherThanCurrent.push({
                      user: group.current_user,
                      group: group.group_name,
                    });
                  }
                }
              });

              if (!!usersOtherThanCurrent.length) {
                toastNotification(
                  'error',
                  getTranslation('toast.error.agentOrGroupInUse', {
                    object: getTranslation('agent'),
                    user: usersOtherThanCurrent.reduce((acc, value) => {
                      return (acc += ` \n\n ${value.user}, ${getTranslation(
                        'group'
                      ).toLowerCase()}: ${value.group}; `);
                    }, ''),
                    status,
                  })
                );
                return false;
              }
            }

            return true;
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [agentName]
  );

  async function publishAgent() {
    const botToStringify = await createBotObject(agentName || '', dispatchApp);
    if (botToStringify && agentName) {
      const { accessKeys, entities, groups } = botToStringify;
      const backupResponse = await SavePublishBackup(
        { botName: agentName, accessKeys, entities, groups },
        dispatchApp
      );

      if (backupResponse.Success) {
        toastNotification(
          'success',
          getTranslation('toast.success.agentBackup'),
          3000
        );
      } else {
        toastNotification(
          'warning',
          getTranslation('toast.warning.agentBackup'),
          3000
        );
      }
    }
    if (state.editorFlow?.drawflow && agentName) {
      await saveFlow();
      const result = await GetFlows(
        {
          bot_name: agentName || '',
        },
        dispatchApp
      );

      if (result.Success) {
        const flows = result.Data.data.groups
          .map((flow) => {
            if (!flow.blocks.drawflow) return null;
            const home = flow.blocks.drawflow.Home;
            const drawflowModel = new DrawflowModel(home.data, flow.group_name);
            home.data = drawflowModel.getDrawflow();
            return {
              bot: flow.bot_name,
              groupName: flow.group_name,
              groupId: flow._id,
              drawflow: { drawflow: { Home: home } },
            } as IFlow;
          })
          .filter((f) => !!f);

        const entitiesResult: IEntity[] = [];

        for (const entity of result.Data.data.entities) {
          const { values } = entity;
          const result = values.filter((v) => !!v.value);
          if (!!result.length) {
            entitiesResult.push({
              ...entity,
              values: result,
            });
          }
        }

        compileFlow(flows, entitiesResult);
        const flowsCompiled: IFlow[] = compileBot(flows);
        tagsCompiler(flowsCompiled);

        console.log(flowsCompiled);
        setBotDrawflow({
          bot_name: agentName || '',
          groups: flowsCompiled.filter((f) => !!f),
          entities: {
            bot_name: agentName || '',
            entities: entitiesResult,
          },
        });

        dispatch({
          type: 'drawflowToPublish',
          data: {
            drawflowToPublish: {
              bot_name: agentName || '',
              groups: flowsCompiled.filter((f) => !!f),
              entities: {
                bot_name: agentName || '',
                entities: entitiesResult,
              },
            },
          },
        });
        dispatch({ type: 'openModalPublishBot' });
      }
    }
  }

  function getAgentDrawflow() {
    return botDrawflow;
  }

  function finishPublish(publishSuccess: boolean, message: string) {
    if (publishSuccess && message === 'Bot publicado com sucesso!') {
      toastNotification(
        'success',
        getTranslation('toast.success.publishAgent'),
        3000
      );
    } else {
      toastNotification(
        'error',
        `
						${getTranslation('toast.error.publishAgent')}
						${message}
					`
      );
    }
    dispatch({ type: 'closeModalPublishBot' });
  }

  return {
    getAgentDrawflow,
    isAgentFreeToUse,
    publishAgent,
    finishPublish,
  };
}
